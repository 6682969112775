import styled from "styled-components";

const Link = styled.span`
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
  ${props =>
    props.margin
        ? "margin: " +
        props.margin.reduce((acc, val) => (acc + val + "px "), "") +
        ";"
        : ""};
`;

export default Link;