import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {FlexContainer} from "../components/FlexContainer";
import {Route} from "react-router-dom";
import {fetchCompanyList, fetchSystemProps,} from "./SystemAdministratorActions";
import Label from "../components/Label";
import {PageHeading} from "../components/PageHeading";
import TwoFactorContainer from "./TwoFactorContainer";
import ModuleForm from "./ModuleForm";
import LoggedInUsers from "./LoggedInUsers";
import {TWO_FACTOR_PROP_KEY} from "../actions/CommonActions";
import UsersTwoFactorDisabledContainer from "./usersWithTwoFactorDisabled/UsersTwoFactorDisabledContainer";
import AdminContainer from "../admin/AdminContainer";
import SSOContainer from "./SSOContainer";
import {
    MAINTENANCE_ENABLED_PROP_KEY,
    MAINTENANCE_ENGLISH_TEXT_PROP_KEY,
    MAINTENANCE_NORWEGIAN_TEXT_PROP_KEY,
    SSO_ENABLED_PROP_KEY
} from "../actions/CommonActionsNoObsvc";
import InsiderSyncContainer from "./InsiderSyncApi/InsiderSyncContainer";
import MaintenanceContainer from "./MaintenanceContainer";

const USERS = "/system";
const MODULES = "/system/modules";
const SETTINGS = "/system/settings";
const ACTIVEUSERS = "/system/activeusers";
const INSIDER_LOG_LINKS_NEW = "/system/insidersync";
const USER_ADMIN = "/system/useradmin";
const SSO_ENABLED = "/system/ssoEnabled";
const MAINTENANCE = "/system/maintenance";


class SystemAdministratorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.findValueOfSystemProp = this.findValueOfSystemProp.bind(this);
  }
  componentWillMount() {
    this.props.dispatch(fetchSystemProps())
    this.props.dispatch(fetchCompanyList())
    if (
      !this.moduleShouldBeShown("twoFactorDisabledUsers") &&
      this.props.location.pathname !== ACTIVEUSERS
    ) {
      this.props.history.push(`${this.props.base}/activeusers`);
    }
  }

  findValueOfSystemProp(sysPropKey) {
    return this.props.systemPropsList.find(
      (element) => element.key === sysPropKey
    );
  }

  moduleShouldBeShown(moduleName) {
    const { config } = this.props;
    return (
      !config ||
      !config.adminOptions ||
      (config &&
        config.adminOptions &&
        config.adminOptions.includes(moduleName))
    );
  }

  render() {
    const { t, base, systemPropsObject } =
      this.props;

    return (
      <FlexContainer>
        <FlexContainer>
          <FlexContainer center row>
            {this.moduleShouldBeShown("twoFactorDisabledUsers") ? (
              <PageHeading
                fontSize={20}
                isFocusable
                onClick={() => {
                  this.props.history.push(`${base}`);
                }}
                opacity={this.props.location.pathname === USERS ? "1.0" : "0.5"}
                underline={this.props.location.pathname === USERS}
                pointer={this.props.location.pathname !== USERS}
                margin={[0, 25, 25, 0]}
                color={"#1c315b"}
              >
                {t("users_two_factor_disabled")}
              </PageHeading>
            ) : undefined}
            {this.moduleShouldBeShown("activeUsers") ? (
              <PageHeading
                fontSize={20}
                isFocusable
                opacity={
                  this.props.location.pathname === ACTIVEUSERS ? "1.0" : "0.5"
                }
                underline={this.props.location.pathname === ACTIVEUSERS}
                pointer={this.props.location.pathname !== ACTIVEUSERS}
                onClick={() => this.props.history.push(`${base}/activeusers`)}
                margin={[0, 25, 25, 0]}
                color={"#1c315b"}
              >
                {t("active_users")}
              </PageHeading>
            ) : undefined}
            {this.moduleShouldBeShown("modules") ? (
              <PageHeading
                fontSize={20}
                isFocusable
                opacity={
                  this.props.location.pathname === MODULES ? "1.0" : "0.5"
                }
                underline={this.props.location.pathname === MODULES}
                pointer={this.props.location.pathname !== MODULES}
                onClick={() => this.props.history.push(`${base}/modules`)}
                margin={[0, 25, 25, 0]}
                color={"#1c315b"}
              >
                {t("modules_settings")}
              </PageHeading>
            ) : undefined}
            {this.moduleShouldBeShown("twoFactorSettings") ? (
              <PageHeading
                fontSize={20}
                isFocusable
                opacity={
                  this.props.location.pathname === SETTINGS ? "1.0" : "0.5"
                }
                underline={this.props.location.pathname === SETTINGS}
                pointer={this.props.location.pathname !== SETTINGS}
                onClick={() => this.props.history.push(`${base}/settings`)}
                margin={[0, 25, 25, 0]}
                color={"#1c315b"}
              >
                {t("two_factor_settings")}
              </PageHeading>
            ) : undefined}

            {this.moduleShouldBeShown("insiderLog") ? (
              <PageHeading
                fontSize={20}
                isFocusable
                opacity={
                  this.props.location.pathname === INSIDER_LOG_LINKS_NEW
                    ? "1.0"
                    : "0.5"
                }
                underline={
                  this.props.location.pathname === INSIDER_LOG_LINKS_NEW
                }
                pointer={this.props.location.pathname !== INSIDER_LOG_LINKS_NEW}
                onClick={() =>
                  this.props.history.push(`${base}/insidersync`)
                }
                margin={[0, 25, 25, 0]}
                color={"#1c315b"}
              >
                {t("system_insider_sync")}
              </PageHeading>
            ) : undefined}
          </FlexContainer>
          <FlexContainer center row>
            {this.moduleShouldBeShown("useradmin") ? (
              <PageHeading
                fontSize={20}
                isFocusable
                opacity={
                  this.props.location.pathname.includes(USER_ADMIN)
                    ? "1.0"
                    : "0.5"
                }
                underline={this.props.location.pathname.includes(USER_ADMIN)}
                pointer={!this.props.location.pathname.includes(USER_ADMIN)}
                onClick={() => this.props.history.push(`${base}/useradmin`)}
                margin={[0, 25, 25, 0]}
                color={"#1c315b"}
              >
                {t("system_user_admin_link")}
              </PageHeading>
            ) : undefined}
            {this.moduleShouldBeShown("ssoEnabled") ? (
              <PageHeading
                fontSize={20}
                isFocusable
                opacity={
                  this.props.location.pathname.includes(SSO_ENABLED)
                    ? "1.0"
                    : "0.5"
                }
                underline={this.props.location.pathname.includes(SSO_ENABLED)}
                pointer={!this.props.location.pathname.includes(SSO_ENABLED)}
                onClick={() => this.props.history.push(`${base}/ssoEnabled`)}
                margin={[0, 25, 25, 0]}
                color={"#1c315b"}
              >
                {t("system_sso_admin_link")}
              </PageHeading>
            ) : undefined}
            {this.moduleShouldBeShown("maintenance") ? (
              <PageHeading
                fontSize={20}
                isFocusable
                opacity={
                  this.props.location.pathname.includes(MAINTENANCE)
                    ? "1.0"
                    : "0.5"
                }
                underline={this.props.location.pathname.includes(MAINTENANCE)}
                pointer={!this.props.location.pathname.includes(MAINTENANCE)}
                onClick={() => this.props.history.push(`${base}/maintenance`)}
                margin={[0, 25, 25, 0]}
                color={"#1c315b"}
              >
                {t("system_maintenance_link")}
              </PageHeading>
            ) : undefined}
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <Route
            exact
            path={`${base}`}
            render={() => {
                return this.moduleShouldBeShown("twoFactorDisabledUsers") ? (
                <UsersTwoFactorDisabledContainer {...this.props} />
              ) : "";
            }}
          />
        </FlexContainer>
        <FlexContainer column>
          <Route
            exact
            path={`${base}/settings`}
            render={() => {
              return this.moduleShouldBeShown("twoFactorSettings") ? (
                <FlexContainer border greyBackground>
                  <FlexContainer center row margin={[16, 0, 12, 16]}>
                    {this.props.t("system_two_factor_status")}
                    <Label margin={[0, 0, 0, 10]} fontSize={16} bold>
                      {this.props.twoFactorStatus === "true"
                        ? this.props.t("system_two_factor_on")
                        : this.props.t("system_two_factor_off")}
                    </Label>
                  </FlexContainer>
                  <TwoFactorContainer
                    enableReinitialize={true}
                    t={t}
                    isActive={this.props.twoFactorStatus}
                    initialValues={{
                      twoFactorStatus: this.props.twoFactorStatus,
                    }}
                    key="twoFactorMenuBody"
                  />
                </FlexContainer>
              ) : (
                ""
              );
            }}
          />
          <Route
            exact
            path={`${base}/modules`}
            render={() => {
              return this.moduleShouldBeShown("modules") ? (
                <ModuleForm forceUpdate />
              ) : (
                ""
              );
            }}
          />
          <Route
            exact
            path={`${base}/activeusers`}
            render={() => {
              return this.moduleShouldBeShown("activeUsers") ? (
                <LoggedInUsers {...this.props} />
              ) : (
                ""
              );
            }}
          />

          <Route
            exact
            path={`${base}/insidersync`}
            render={() => {
              return this.moduleShouldBeShown("insiderLog") ? (
                <InsiderSyncContainer
                  key="insiderSync"
                  t={t}
                  {...this.props}
                  systemProps={systemPropsObject}
                />
              ) : (
                ""
              );
            }}
          />

          <Route
            path={`${base}/useradmin/:page?`}
            render={() => {
              return this.moduleShouldBeShown("useradmin") ? (
                <AdminContainer forceUpdate base={`${base}/useradmin`} />
              ) : (
                ""
              );
            }}
          />

          <Route
            exact
            path={`${base}/ssoEnabled`}
            render={() => {
              return this.moduleShouldBeShown("ssoEnabled") ? (
                <FlexContainer border greyBackground>
                  <FlexContainer center row margin={[16, 0, 12, 16]}>
                    {this.props.t("system_sso_status")}
                    <Label margin={[0, 0, 0, 10]} fontSize={16} bold>
                      {this.props.ssoEnabled === "true"
                        ? this.props.t("system_sso_on")
                        : this.props.t("system_sso_off")}
                    </Label>
                  </FlexContainer>
                  <SSOContainer
                    enableReinitialize={true}
                    t={t}
                    isActive={this.props.ssoEnabled}
                    initialValues={{
                      ssoEnabled: this.props.ssoEnabled,
                    }}
                    key="ssoEnabledContainer"
                  />
                </FlexContainer>
              ) : (
                ""
              );
            }}
          />

            <Route
                exact
                path={`${base}/maintenance`}
                render={() => {
                    return this.moduleShouldBeShown("maintenance") ? (
                        <FlexContainer border greyBackground>
                            <FlexContainer>
                                <FlexContainer center row margin={[16, 0, 12, 16]}>
                                    {this.props.t("system_maintenance_status")}
                                    <Label margin={[0, 0, 0, 10]} fontSize={16} bold>
                                        {this.props.maintenanceEnabled === "true"
                                            ? this.props.t("system_maintenance_on")
                                            : this.props.t("system_maintenance_off")}
                                    </Label>
                                </FlexContainer>
                                <FlexContainer trueCenter margin={[16, 0, 12, 16]}>
                                        {this.props.t("system_maintenance_text_norwegian")}
                                        <Label fontSize={16} bold>
                                            {this.props.maintenanceNorwegianText}
                                        </Label>
                                </FlexContainer>
                                <FlexContainer trueCenter margin={[16, 0, 12, 16]}>
                                    {this.props.t("system_maintenance_text_english")}
                                    <Label fontSize={16} bold>
                                        {this.props.maintenanceEnglishText}
                                    </Label>
                                </FlexContainer>
                            </FlexContainer>
                            <MaintenanceContainer
                                enableReinitialize={true}
                                t={t}
                                isActive={this.props.maintenanceEnabled}
                                initialValues={{
                                    maintenanceEnabled: this.props.maintenanceEnabled,
                                    norwegianText: this.props.maintenanceNorwegianText,
                                    englishText: this.props.maintenanceEnglishText,
                                }}
                                key="maintenanceContainer"
                            />
                        </FlexContainer>
                    ) : (
                        ""
                    );
                }}
            />
        </FlexContainer>
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { systemAdministratorReducer, insiderSyncDetailsReducer, config } =
    state;

  return {
    twoFactorStatus:
      systemAdministratorReducer.systemProps.object[TWO_FACTOR_PROP_KEY],
    isUsersTwoFactorDisabledFetched:
      systemAdministratorReducer.usersTwoFactorDisabledList.isFetched,
    systemPropsList: systemAdministratorReducer.systemProps.list,
    systemPropsObject: systemAdministratorReducer.systemProps.object,
    companyList: systemAdministratorReducer.companyList.list,
    config: config.config,
    ssoEnabled:
      systemAdministratorReducer.systemProps.object[SSO_ENABLED_PROP_KEY],
    insiderSyncDetails: insiderSyncDetailsReducer.insiderSyncDetialsList.list,
      maintenanceEnabled:
          systemAdministratorReducer.systemProps.object[MAINTENANCE_ENABLED_PROP_KEY],
      maintenanceNorwegianText:
          systemAdministratorReducer.systemProps.object[MAINTENANCE_NORWEGIAN_TEXT_PROP_KEY],
      maintenanceEnglishText:
          systemAdministratorReducer.systemProps.object[MAINTENANCE_ENGLISH_TEXT_PROP_KEY],
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(SystemAdministratorContainer)
);
