import React from "react";
import {change, destroy, Field, reduxForm, SubmissionError} from "redux-form";
import {FlexContainer} from "../components/FlexContainer";
import {HeaderPanel} from "../components/HeaderPanel";
import Input from "../components/Input";
import Label from "../components/Label";
import Button from "../components/Button";
import CheckBox from "../components/CheckBox";
import SearchableSelect from "../components/SearchableSelect";
import {userAction} from "./AdminContainer";
import RadioButton from "../components/RadioButton";
import {ErrorText} from "../components/ErrorText";
import {getLanguage, getOBNTDateTime} from "../util/common";
import {Modal} from "../components/Modal";
import styled from "styled-components";
import SearchableSelectNew from "../components/SearchableSelectNew";
import {clearValidKeys, getApiKeys, getPublicKeys, setCredentials, setPublicKey, updatePublicKey} from "./AdminActions";
import {NotificationManager} from "react-notifications";
import {validatePassword} from "../util/Validators";
import ApiKeyManagement from "./ApiKeyManagement";
import moment from "moment/moment";

const InfoText = styled.p`
  color: red;
`;

const NewSelectDropDown = (props) => {
  const { error } = props.meta;

  return (
    <FlexContainer maxWidth={295} margin={[0, 8, 0, 0]}>
      <Label htmlFor={props.name} margin={[8, 8, 3, 0]}>
        {props.label}:
        {error ? (
          <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
        ) : undefined}
      </Label>

      <SearchableSelectNew
        {...props}
        name={props.name}
        placeholder={props.placeholder}
        searchable={true}
        component={SearchableSelectNew}
        options={props.options}
        t={props.t}
      />
    </FlexContainer>
  );
};
class UserEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPasswordReset: false,
      isTwoFactorActive: true,
      openSetPasswordModal: false,
      lastPasswordChange: undefined,
      openKeyManagementModal: false,
      apiRole: false,
      selectedUserType: undefined
    };
  }

  componentDidMount() {
      if (this.props.user && this.props.user.lastPasswordChange !== undefined) {
          this.setState({lastPasswordChange: this.props.user.lastPasswordChange})
      }
      if (this.props.user && this.props.user.userType === "newsapi") {
          this.setState({apiRole: "newsapi"})
      }
      else if (this.props.user && this.props.user.userType === "insidersyncapi") {
          this.setState({apiRole: "insidersyncapi"})
      }
  }

  onPasswordChange = (password, dispatch, props) => {
        const sendPasswordValues = () => {
            return dispatch(
                setCredentials(
                    this.props.user.globalSubject,
                    null,
                    null,
                    null,
                    null,
                    null,
                    1,
                    null,
                    password.repeatPassword
                )
            );
        };
        const handleServerErrors = (error, reject) => {
            if (error.data.details != null) {
                const details = error.data.details;
                const hasDetailWithCode = (details, code) => details.some((detail) => detail.code === code);
                const submissionErrorWithTranslationText = (translationKey) => new SubmissionError({
                    newPassword: this.props.t(translationKey), repeatPassword: this.props.t(translationKey),
                })

                switch (true) {
                    case hasDetailWithCode(details, 1221):
                        return reject(submissionErrorWithTranslationText("password_missing_char"))
                    case hasDetailWithCode(details, 1222):
                        return reject(submissionErrorWithTranslationText("password_too_short"))
                    case hasDetailWithCode(details, 1223):
                        return reject(submissionErrorWithTranslationText("password_reused"))
                    case hasDetailWithCode(details, 1224):
                        return reject(submissionErrorWithTranslationText("password_username"))
                    case hasDetailWithCode(details, 1225):
                        return reject(submissionErrorWithTranslationText("password_missing_char"))
                    case hasDetailWithCode(details, 1226):
                        return reject(submissionErrorWithTranslationText("password_consecutive_char"))
                    default:
                        return reject()
                }
            } else
                NotificationManager.error(
                    this.props.t("an_error_occured"),
                    "",
                    0
                );
            return reject();
        };

        const requiredFieldsList = [
            "newPassword",
            "repeatPassword",
        ];

        const errors = validatePassword(
            password,
            props,
            requiredFieldsList
        );

        return new Promise((resolve, reject) => {
            if (errors) {
                return reject(new SubmissionError(errors));
            }
            sendPasswordValues()
                .then(() =>
                    NotificationManager.success(this.props.t("password_updated"))
                )
                .then(() =>
                    this.setState({ openSetPasswordModal: false, lastPasswordChange: new Date() })
                )
                .then(resolve)
                .catch((error) => handleServerErrors(error, reject));
        });
    }

    onPublicKeySubmit = (values) => {
        const sendKeyValues = () => {
          if (values.isUpdate) {
              return this.props.dispatch(
                  updatePublicKey(
                      this.props.user.globalSubject,
                      values
                  )
              );
          } else {
              return this.props.dispatch(
                  setPublicKey(
                      this.props.user.globalSubject,
                      values
                  )
              );
          }
        };
        const handleServerErrors = (error, reject) => {
            NotificationManager.error(
                this.props.t("an_error_occured"),
                "",
                0
            );
            return reject();
        };

        let valueObj = values;
        valueObj.expires = moment(valueObj.expires, "DD.MM.YYYY", true).isValid()
            ? moment(valueObj.expires, "DD.MM.YYYY").toISOString()
            : moment(valueObj.expires, "YYYY-MM-DD", true).isValid()
                ? moment(valueObj.expires, "YYYY-MM-DD", true).toISOString() : undefined;

        return new Promise((resolve, reject) => {
            sendKeyValues()
                .then(() =>
                    NotificationManager.success(this.props.t("key_updated"))
                )
                .then(() =>
                    this.setState({ openKeyManagementModal: false })
                )
                .then(resolve)
                .then(this.props.dispatch(destroy("publicKeyForm")))
                .catch((error) => handleServerErrors(error, reject));
        });
    }

    onUserTypeChange = (type) => {
        if (type === "newsapi" || type === "insidersyncapi") {
            this.setState({apiRole: type})
        } else {
            this.setState({apiRole: undefined})
        }
        if (type === this.state.selectedUserType) {
            // TO OVERRIDE RADIO-BUTTON BEHAVIOUR AND BE ABLE TO DESELECT
            this.setState({selectedUserType: undefined}, () => {
                this.props.dispatch(change("userForm", "userType", "unknown"));
                this.props.dispatch(change("userForm", "user", {}));
                this.props.dispatch(change("userForm", "admin", {}));
                this.props.dispatch(change("userForm", "roles", []));
            });
        } else {
            this.setState({selectedUserType: type});
        }
    }

    handleOpenKeyManagementModal = () => {
        if (this.state.apiRole === "newsapi") {
            this.props.dispatch(getPublicKeys(this.props.user.globalSubject));
        }
        else if (this.state.apiRole === "insidersyncapi") {
            this.props.dispatch(getApiKeys(this.props.user.globalSubject));
        }
        this.setState({ openKeyManagementModal: true });
    }

  render() {
    const { t, user } = this.props;
    const props = this.props;

    return (
      <FlexContainer column>
        <HeaderPanel>
          {user != null
            ? user.username +
              (!this.props.userEnabled ? " [" + props.t("deactive") + "]" : "")
            : props.t("new_user")}
        </HeaderPanel>

        <UserForm
          {...user}
          {...props}
          enableReinitialize={false}
          initialValues={{
            ...user,
            enableTwoFactor:
              user != null
                ? user.enableTwoFactor && this.props.systemWideTwoFactorEnabled
                : this.props.systemWideTwoFactorEnabled,
            organisation: props.preSelectOrganisation
              ? {
                  label: props.organisation.name,
                  value: {
                    id: props.organisation.id,
                    isInternal: props.organisation.internal,
                  },
                }
              : undefined,
            userType: props.userType,
            organisationId: this.props.organisationId,
            areaCode: {
              label: props.initialAreaCode
                ? props.initialAreaCode.label
                : undefined,
              value: props.initialAreaCode
                ? props.initialAreaCode.value
                : undefined,
            },
            twoFactorProtocol:
              this.props.user &&
              this.props.user.otherProperties.twoFactorMessageProtocol
                ? this.props.user.otherProperties.twoFactorMessageProtocol
                : "SMS",
            twoFactorPhone:
              this.props.user && this.props.user.otherProperties.twoFactorPhone,
          }}
          languageOptions={props.languageOptions}
          toggleTwoFactorInfo={() =>
            this.setState({ isTwoFactorActive: !this.state.isTwoFactorActive })
          }
          isTwoFactorActive={this.state.isTwoFactorActive}
          onResetPassword={() =>
            this.props.handlePasswordReset(this.props.user, this.props)
          }
          onOpenSetPasswordModal={() => {this.setState({ openSetPasswordModal: true })}}
          onCloseSetPasswordModal={() => {
              this.setState({ openSetPasswordModal: false });
          }}
          lastPasswordChange={this.state.lastPasswordChange}
          onOpenKeyManagementModal={this.handleOpenKeyManagementModal}
          onUserTypeChange={this.onUserTypeChange}
          showSetPublicKey={this.props.publicKeyPermission && this.state.apiRole !== undefined}
          apiRole={this.state.apiRole}
          t={t}
        />

        <PasswordForm {...props} onPasswordSubmit={this.onPasswordChange}
                      apiRole={this.state.apiRole}
                      openSetPasswordModal={this.state.openSetPasswordModal}
                      onCloseSetPasswordModal={() => {
                          this.setState({ openSetPasswordModal: false });
                      }}
        />

          {this.state.openKeyManagementModal &&
              <ApiKeyManagement
              apiRole={this.state.apiRole}
              onSubmitPublicKey={this.onPublicKeySubmit}
              openKeyManagementModal={this.state.openKeyManagementModal}
              onCloseKeyManagementModal={() => {
                  this.props.dispatch(clearValidKeys())
                  this.setState({ openKeyManagementModal: false })}}
              validKeys={this.props.validKeys}
              apiKeys={this.props.apiKeys}
              {...props}
          /> }
      </FlexContainer>
    );
  }
}

class UserForm extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <form autoComplete="off">
        <FlexContainer greyBackground row padding={[16, 16, 16, 16]}>
          <LeftContentBox t={t} {...this.props}/>

          <RightContentBox t={t} {...this.props} />
        </FlexContainer>
        <BottomActionMenu
          t={t}
          userActivityButtonLabel={
            this.props.userEnabled ? t("deactivate") : t("activate")
          }
          {...this.props}
        />
      </form>
    );
  }
}

UserForm = reduxForm({
  form: "userForm",
})(UserForm);

class PasswordForm extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <form autoComplete="off">
        <Modal
          isOpen={this.props.openSetPasswordModal}
          header={t("set_password", {
            user: this.props.user != null ? this.props.user.username : "",
          })}
          onClose={() => this.props.onCloseSetPasswordModal()}
        >
          <FlexContainer>
            <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
              <FlexContainer>
                <Field
                  border
                  name="newPassword"
                  component={InputBox}
                  label={t("password") + ":"}
                  placeholder={t("password")}
                  type="password"
                  greyPlaceholder
                />

                <Field
                  border
                  name="repeatPassword"
                  component={InputBox}
                  label={t("repeat_password") + ":"}
                  placeholder={t("repeat_password")}
                  onKeyDown={(e) => {
                    switch (e.keyCode) {
                      case 13: //Enter
                      //props.onClickAction(loginValues);
                    }
                  }}
                  type="password"
                  greyPlaceholder
                />

                <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
                  <Button.Standard
                    margin={[0, 16, 0, 0]}
                    onClick={this.props.handleSubmit(
                        this.props.onPasswordSubmit
                    )}
                  >
                    {t("save")}
                  </Button.Standard>
                  <Button.Text
                    onClick={() => this.props.onCloseSetPasswordModal()}
                  >
                    {t("cancel")}
                  </Button.Text>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </Modal>
      </form>
    );
  }
}

PasswordForm = reduxForm({
  form: "passwordForm",
})(PasswordForm);
//const selector = formValue;

const LeftContentBox = (props) => (
  <FlexContainer column>
    <Field
      name="firstName"
      component={InputBox}
      label={props.t("first_name") + "*"}
    />
    <Field
      name="lastName"
      component={InputBox}
      label={props.t("last_name") + "*"}
    />
    <Field
      name="mobile"
      component={InputBox}
      label={props.t("mobile_number") + "*"}
    />
    <Field
      name="office"
      component={InputBox}
      label={props.t("direct_number")}
    />
    <Field name="email" component={InputBox} label={props.t("email") + "*"} />
    <Field
      name="organisation"
      component={SelectBox}
      t={props.t}
      onSelect={props.onSelectOrganisation}
      label={props.t("organisation") + "*"}
      placeholder={props.t("select")}
      options={props.organisationList}
      disabled={
        props.disableOrganisationList || props.action === userAction.EDIT
      }
    />

    {props.action === userAction.EDIT ? (
      <InformationBox
        label={props.t("username") + "*"}
        name="username"
        margin={[0, 26, 16, 0]}
        maxWidth={0}
      >
        {props.user.username}
      </InformationBox>
    ) : (
      <FlexContainer row>
        <Field
          name="username"
          loginNamePrefix={props.loginNamePrefix}
          component={UserNameBox}
          label={props.t("username") + "*"}
        />
      </FlexContainer>
    )}

    <Field
      name="language"
      t={props.t}
      component={NewSelectDropDown}
      options={props.languageOptions}
      placeholder={props.t("select")}
      margin={[0, 0, 24, 0]}
      label={props.t("language") + "*"}
      selectIfOnlyOneOption
      forceUpdate={true}
    />

    {props.action === userAction.EDIT ? (
      <FlexContainer>
        <InformationBox
          label={props.t("last_password_update")}
          name="passwordUpdate"
        >
          {props.lastPasswordChange != null
            ? getOBNTDateTime(props.lastPasswordChange)
            : ""}
        </InformationBox>
        <InformationBox label={props.t("last_login")} name="lastLogin">
          {props.lastLogin != null ? getOBNTDateTime(props.lastLogin) : ""}
        </InformationBox>
        <InformationBox label={props.t("invalid_logins")} name="invalidLogins">
          {props.invalidLogons}
        </InformationBox>
      </FlexContainer>
    ) : undefined}
  </FlexContainer>
);

const InputBox = (props) => {
  const { error } = props.meta;

  return (
    <FlexContainer column maxWidth={295} margin={[0, 0, 16]}>
      <Label htmlFor={props.input.name} margin={[0, 0, 8, 0]}>
        {props.label}
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <Input
        margin={[0, 4, 0, 0]}
        id={props.input.name}
        {...props.input}
        {...props}
      />
    </FlexContainer>
  );
};

const SelectBox = (props) => {
  const { margin, ...restProps } = props;
  const { error } = props.meta;

  return (
    <FlexContainer column maxWidth={295} margin={[0, 0, 16, 0]} {...props}>
      <Label htmlFor={props.input.name} margin={[0, 0, 8, 0]}>
        {props.label}
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <SearchableSelect
        {...restProps}
        id={props.input.name}
        noBorder
        searchable={true}
      />
    </FlexContainer>
  );
};

const InformationBox = (props) => (
  <FlexContainer row>
    <Label
      htmlFor={props.name}
      maxWidth={295}
      margin={[0, 16, 16, 0]}
      bold
      {...props}
    >
      {props.label}:
    </Label>
    <span id={props.name}>{props.children}</span>
  </FlexContainer>
);

const UserNameBox = (props) => {
  const { touched, error } = props.meta;
  return (
    <FlexContainer column maxWidth={295} margin={[0, 0, 16]}>
      <Label htmlFor={props.input.name} margin={[0, 0, 8, 0]}>
        {props.label}
      </Label>
      {touched && error ? <ErrorText>{error}</ErrorText> : undefined}
      <FlexContainer row vAlignCenter>
        <Label margin={[0, 2, 0, 0]}>
          {props.loginNamePrefix && props.loginNamePrefix.length > 0
            ? props.loginNamePrefix + "-"
            : ""}
        </Label>
        <Input margin={[0, 4, 0, 0]} id={props.input.name} {...props.input} />
      </FlexContainer>
    </FlexContainer>
  );
};

const LabelWithError = (props) => {
  const { error } = props.meta;
  return (
    <FlexContainer margin={[20, 0, 16, 0]}>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <Label bold>{props.label}:</Label>
    </FlexContainer>
  );
};

const RightContentBox = (props) => {
  const userTypes = props.getUserTypes();

  const countryOptions =
    props.parameterList && props.parameterList.countries
      ? props.parameterList.countries.map((country) => {
          return {
            label: getLanguage() === "No" ? country.nameNo : country.nameEn,
            value: country.id,
          };
        })
      : [];

  const getAuthOptions = () => {
    return props.otherProperties
      ? Object.keys(props.otherProperties)
          .filter((property) => property.startsWith("type_"))
          .map((property) => (
            <Field
              key={property}
              fitToContent
              component={CheckBox}
              margin={[0, 0, 8, 0]}
              label={props.t(property)}
              name={property}
              checked={props.otherProperties[property] === "true"}
            />
          ))
      : props.userParams && props.userParams.userprofileTypes
      ? Object.keys(props.userParams.userprofileTypes)
          .filter((property) => property.startsWith("type_"))
          .map((property) => (
            <Field
              key={property}
              fitToContent
              component={CheckBox}
              margin={[0, 0, 8, 0]}
              label={props.t(property)}
              name={property}
              checked={props.userParams.userprofileTypes[property] === "true"}
            />
          ))
      : undefined;
  };

  return (
    <FlexContainer column>
        <FlexContainer row>
            <FlexContainer column>
              {userTypes != null ? (
                [
                  <Label key="roleGroupLabel" bold margin={[0, 0, 16, 0]}>
                    {props.t("roles")}:*
                  </Label>,
                  Object.keys(userTypes).sort().map((userType, index) => {
                    return (
                      <Field
                        name="userType"
                        key={userType}
                        language={props.language}
                        t={props.t}
                        index={index}
                        component={RadioButtonUserTypeGroup}
                        userTypeName={userTypes[userType].id}
                        userTypeNameLabel={`user_type_${userType}`}
                        userTypeRoles={userTypes[userType].userRoles}
                        hiddenRoles={props.hiddenRoles}
                        onChange={props.onUserTypeChange}
                      />
                    );
                  }),
                ]
              ) : (
                <Field
                  name="userType"
                  isHidden
                  transparent
                  readOnly
                  component={Input}
                />
              )}
            </FlexContainer>
            <FlexContainer>
                <Label key="unknownRoleGroupLabel" bold margin={[0, 0, 16, 0]}>
                    {props.t("unknown_roles")}
                </Label>
                <Field
                    name="unknownRoles"
                    component={InputBox}
                />
            </FlexContainer>
      </FlexContainer>
      {props.showTwoFactorCheckBox ? (
        <FlexContainer>
          <Label key={"2mfa"} bold margin={[20, 0, 16, 0]}>
            {props.t("signin")}:
          </Label>

          <Field
            disabled={!props.editTwoFactor}
            component={TwoFactorCheckBox}
            toggleTwoFactorInfo={props.toggleTwoFactorInfo}
            name="enableTwoFactor"
            label={props.t("activate_mfa")}
          />

          {props.isTwoFactorActive === false &&
          props.action === userAction.NEW ? (
            <InfoText>{props.t("setPasswordReminder")}</InfoText>
          ) : (
            ""
          )}

          <Field
            name={"twoFactorProtocol"}
            t={props.t}
            component={RadioButtonTwoFactorProtocolGroup}
            twoFactorMessageProtocol={props.twoFactorMessageProtocol}
          />

          <Label>{props.t("phonenumber_for_receival_mfa")}:</Label>
          <FlexContainer row margin={[8, 0, 30, 0]}>
            <Field
              t={props.t}
              component={SearchableSelect}
              searchable={true}
              name="areaCode"
              maxWidth={200}
              margin={[0, 24, 0, 0]}
              placeholder={props.t("choose_area_code")}
              options={countryOptions}
            />
            <Field
              component={Input}
              name={"twoFactorPhone"}
              border
              maxWidth={208}
              margin={[0, 24, 0, 0]}
              placeholder={props.t("telephonenumber")}
            />
          </FlexContainer>
        </FlexContainer>
      ) : undefined}
      <Label margin={[0, 0, 8, 0]}>{props.t("issuers")}:</Label>
            <CompanyListBox>
                {props.companyList ?
                    (props.companyList.map((comp) => comp + "\n")) : []
                }
            </CompanyListBox>
      <FlexContainer row>
        {props.action === userAction.EDIT ? (
          <Button.Standard
            onClick={props.handleSubmit(props.onResetPassword)}
            margin={[16, 0, 0, 0]}
          >
            {props.t("reset_password")}
          </Button.Standard>
        ) : undefined}
        {props.action === userAction.EDIT && props.showSetPasswordButton ? (
          <Button.Standard
            onClick={props.onOpenSetPasswordModal}
            margin={[16, 0, 0, 32]}
          >
            {props.t("set_password", {
              user: props.user != null ? props.user.username : "",
            })}
          </Button.Standard>
        ) : undefined}
      </FlexContainer>
      {props.action === userAction.EDIT && props.showSetPublicKey ? (
        <FlexContainer row>
          <Button.Standard
            onClick={props.onOpenKeyManagementModal}
            margin={[16, 0, 0, 0]}
          >
              {props.apiRole === "newsapi" ? props.t("public_keys") : props.t("api_keys")}
          </Button.Standard>
        </FlexContainer>
      ) : undefined}
      <FlexContainer margin={[20, 0, 0, 0]}>
        <Field
          component={LabelWithError}
          name={"auth_option_header"}
          label={props.t("auth_options")}
        />
        {getAuthOptions()}
      </FlexContainer>
    </FlexContainer>
  );
};

const TwoFactorCheckBox = (props) => (
  <FlexContainer margin={[0, 0, 0, 0]}>
    <CheckBox
      {...props.input}
      {...props}
      checked={props.input.value}
      onChange={(value) => {
        props.input.onChange(value);
        props.toggleTwoFactorInfo();
      }}
    />
  </FlexContainer>
);

const CompanyListBox = (props) => {
    const companies = props.children.filter((company) => company !== "undefined\n");
    return (
        <FlexContainer
            preWrap
            scrollbarsOnOverflow
            whiteBackground
            height={"125px"}
            padding={[8, 8, 8, 8]}
        >
            {companies}
        </FlexContainer>
    )

}

const RadioButtonUserTypeGroup = (props) => {
  const { error } = props.meta;

  return (
    <FlexContainer column>
      {error && props.index === 0 ? <ErrorText>{error}</ErrorText> : undefined}
      <RadioButton
        fitToContent
        margin={[0, 0, 8, 0]}
        radioValue={props.userTypeName}
        label={props.t(props.userTypeNameLabel)}
        {...props}
      />
      {props.userTypeRoles != null ? (
        <FlexContainer
          hidden={!(props.input.value === props.userTypeName)}
          column
          margin={[8, 0, 0, 16]}
        >
          {props.userTypeRoles
                .filter((role) =>
                  props.hiddenRoles
                    ? !props.hiddenRoles.includes(role.id)
                    : true
                )
                .map((role) => (
                  <Field
                    key={role.id}
                    fitToContent
                    component={CheckBox}
                    margin={[0, 0, 8, 0]}
                    label={props.t(`user_type_role_${role.id}`)}
                    name={`${props.userTypeName}.${role.id}`}
                  />
                ))}
        </FlexContainer>
      ) : undefined}
    </FlexContainer>
  );
};

const RadioButtonTwoFactorProtocolGroup = (props) => {
  return (
    <FlexContainer row margin={[24, 0, 10, 0]} vAlignCenter>
      <RadioButton
        {...props}
        margin={[0, 0, 8, 0]}
        radioValue={"SMS"}
        label={props.t("sms")}
      />
      <RadioButton
        {...props}
        component={RadioButton}
        margin={[0, 0, 8, 10]}
        radioValue={"VOICE"}
        label={props.t("speech_message")}
      />
    </FlexContainer>
  );
};

const Icon = styled.img`
  cursor: pointer;
  padding: 0 5px 0 5px;
  margin-right: 8px;
  box-sizing: border-box;
`;
const BottomActionMenu = (props) => {
  return (
    <FlexContainer row spaceBetween margin={[16, 0, 0, 0]}>
      <FlexContainer row vAlignCenter onClick={props.onCancel}>
        <Icon src="/icons/back-arrow-24-px.svg" />
        <Button.Text>{props.t("back")}</Button.Text>
      </FlexContainer>
      <FlexContainer row flexEnd vAlignCenter>
        {props.showDeactivateButton ? (
          <Button.Standard
            onClick={() => props.onToggleUserActivity(props.organisation.id)}
            inverted
            margin={[0, 24, 0, 0]}
          >
            {props.userActivityButtonLabel}
          </Button.Standard>
        ) : undefined}
        <Button.Standard
          inactive={props.submitting || props.pristine}
          onClick={
            !(props.submtting || props.pristine) ? props.onSave : undefined
          }
          margin={[0, 24, 0, 0]}
        >
          {props.t("save")}
        </Button.Standard>
        <Button.Text
          onClick={props.dirty ? props.onCancel : undefined}
          inactive={props.pristine}
        >
          {props.t("cancel")}
        </Button.Text>
      </FlexContainer>
    </FlexContainer>
  );
};

export default UserEdit;
