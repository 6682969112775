import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {NotificationManager} from "react-notifications";

import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {change, destroy, SubmissionError, submit} from "redux-form";

import Organisation from "./Organisation";
import {PageHeading} from "../components/PageHeading";
import {FlexContainer} from "../components/FlexContainer";
import UserAdmin from "./UserAdmin";
import UserEdit from "./UserEdit";
import {
  activateUser,
  clearApiKeys, clearOrganisation,
  clearUserList,
  clearValidKeys,
  createOrganisation,
  createUser,
  deactivateUser,
  fetchOrganisation,
  fetchOrganisationList,
  fetchUser,
  fetchUserList,
  fetchUserParams,
  resetUserPassword,
  toggleOrganisationActivity,
  updateOrganisation,
  updateUser,
} from "./AdminActions";
import {fetchCompanyList} from "../actions/CommonActions";

import i18n from "../i18n";
import "react-notifications/lib/notifications.css";
import Loader from "../components/Loader";
import {allAuthValuesAreFalse, emailValidator, userFormValidator,} from "./Validators";
import {ConfirmModal} from "../components/ConfirmModal";
import {getLanguage, stringHasValue} from "../util/common";
import styled from "styled-components";
import {fetchParameters} from "../companyInformation/CompanyInformationActions";

const ORGANIZATION = "organisation";
const USERADMIN = "useradmin";
const USEREDIT = "user";

export const userAction = {
  EDIT: "edit",
  NEW: "newUser",
};

export const OrganisationActions = {
  CREATE_NEW: "new",
  EDIT: "edit",
};

export const UserTypes = {
  ADMIN: "admin",
  SUPERUSER: "superuser",
  NEWSAPI: "newsapi",
  ISSUERAPI: "issuerapi",
  USER: "user",
  INSIDERSYNCAPI: "insidersyncapi",
  CORPORATE: "corporate",
  UNKNOWN: "unknown"
};

export const RoleTip = styled.span`
  visibility: hidden;
  color: #000;
  text-decoration: none;
  padding: 3px;
  position: absolute;
  background-color: #fff;
  margin: 20px 2px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.15);
`;

export const RoleTipContiner = styled.a`
  &:hover ${RoleTip} {
    visibility: visible;
  }
`;

class AdminContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCompanyListInUserEdit: false,
      showAdminCreationModal: false,
      insider_admin: false,
      loading: false,
      showOnlyActiveOrganisationsInList: true,
    };
  }

  componentWillMount() {
    const re = new RegExp(`${this.props.base}/${USEREDIT}/(\\d+)`, "g");
    const userId = re.exec(this.props.location.pathname);

    this.setState({ loading: true }, () => {
      this.props
        .dispatch(fetchUserParams())
        .then(() => this.props.dispatch(fetchOrganisationList()))
        // .then(() => this.props.dispatch(fetchCompanyList()))
        .then(() =>
          !this.props.permissions.includes("module.useradmin.all")
            ? this.props.dispatch(fetchUserList(this.props.userOrganisation.id))
            : undefined
        )
        .then(() => {
          if (userId != null) {
            return this.props
              .dispatch(fetchUser(userId[1]))
              .then(( res ) => {
                if (res.data.entity !== undefined) {
                  return this.props.dispatch(fetchOrganisation(res.data.entity.organisationId))
                }
              });
          }
          return Promise.resolve();
        })
        .then(() => {
          this.props.dispatch(fetchParameters()).catch(() => {});
        })
        .then(() => this.setState({ loading: false }));

      if (
        this.props.permissions.some(
          (value) => value === "module.useradmin.all"
        ) &&
        this.props.location.pathname.includes(`${this.props.base}/user/`)
      ) {
        this.setState({ showCompanyListInUserEdit: true });
      }
    });
  }

  getUserType = (userId) => {
    if (userId != null) {
      if (this.props.user && this.props.user.roles) {
        if (
          this.props.user.roles.some(
            (value) => value.toLowerCase() === UserTypes.ADMIN
          )
        )
          return UserTypes.ADMIN;

        if (
          this.props.user.roles.some(
            (value) => value.toLowerCase() === UserTypes.SUPERUSER
          )
        )
          return UserTypes.SUPERUSER;

        if (
          this.props.user.roles.some(
            (value) => value.toLowerCase() === UserTypes.NEWSAPI
          )
        )
          return UserTypes.NEWSAPI;

        if (
          this.props.user.roles.some(
            (value) => value.toLowerCase() === UserTypes.INSIDERSYNCAPI
          )
        )
          return UserTypes.INSIDERSYNCAPI;

        if (
            this.props.user.roles.some(
                (value) => value.toLowerCase() === UserTypes.CORPORATE
            )
        )
          return UserTypes.CORPORATE;

        if (
            this.props.user.roles.some(
                (value) => value.toLowerCase() === UserTypes.USER
            )
        )
          return UserTypes.USER;

        return UserTypes.UNKNOWN;
      }
    }
    return undefined;
  };

  render() {
    const { t, match, base } = this.props;
    const { params } = match;

    const onUserClick = (userId) => {
      this.setState({ loading: true }, () => {
        this.props
          .dispatch(fetchUser(userId))
          .then((data) =>
            this.props.dispatch(
              fetchOrganisation(data.data.entity.organisationId)
            )
          )
          .then(() =>
            this.setState({ showCompanyListInUserEdit: true, loading: false })
          )
          .then(() => this.props.history.push(`${base}/user/${userId}`))
          .catch((error) => console.log(error));
      });
    };

    const toggleOrganisationStatus = () => {
      const showNotification = () =>
        NotificationManager.success(t("organisation_updated"));

      if (this.props.organisationIsFetched) {
        return this.props
          .dispatch(
            toggleOrganisationActivity(
              this.props.organisation.id,
              String(!this.props.organisation.active)
            )
          )
          .then(() =>
            this.props.dispatch(fetchOrganisation(this.props.organisation.id))
          )
          .then(() =>
            this.props.dispatch(fetchUserList(this.props.organisation.id))
          )
          .then(showNotification)
          .catch((err) => console.log(err));
      }
    };

    const encodeUser = (user) => {
      var formattedValues = {};
      Object.assign(formattedValues, user);

      formattedValues["preferedLang"] = user.language.value;
      if (this.props.userParams.userTypes != null) {
        Object.keys(this.props.userParams.userTypes).forEach((userType) => {
          if (user.userType === userType) {
            formattedValues["roles"] = [userType];
            if (user[userType] != null) {
              Object.keys(user[userType]).forEach((role) => {
                if (user[userType][role]) {
                  formattedValues["roles"].push(role);
                }
              });
            }
          }
        });
      }
      const unknownRolesAsArray = user.unknownRoles?.split(",").map((role) => role.trim()).filter(stringHasValue);
      if (unknownRolesAsArray !== undefined) {
        if (!formattedValues.roles) {
          formattedValues.roles = [];
        }
        unknownRolesAsArray.forEach((role) => {
          if (!formattedValues.roles.includes(role)) {
            formattedValues.roles.push(role);
          }
        });
      }

      formattedValues["phoneNumbers"] = [
        { phoneType: "MOBILE", phone: user.mobile },
        { phoneType: "OFFICE", phone: user.office },
      ];
      formattedValues["organisationId"] = user.organisation
        ? user.organisation.value.id
        : null;
      formattedValues["title"] = "";
      if (!formattedValues.otherProperties) {
        formattedValues.otherProperties = {};
      }
      formattedValues.otherProperties.twoFactorCountryCode =
        user.areaCode.value;
      formattedValues.otherProperties.twoFactorPhone = user.twoFactorPhone;
      formattedValues.otherProperties.twoFactorMessageProtocol =
        user.twoFactorProtocol;

      //Add all values from otherProperties["type_"] if changed in UI
      Object.keys(user)
        .filter((key) => key.startsWith("type_"))
        .forEach((key) => {
          formattedValues.otherProperties[key] = user[key] + "";
        });

      Object.keys(formattedValues)
        .filter((key) => key.startsWith("type_"))
        .forEach((key) => {
          delete formattedValues[key];
        });

      //filter out only valid values
      const {
        organisation,
        mobile,
        office,
        language,
        enabled, // we don't want to sendt this flag, as this is handled by a seperate button (Activate/Deactivate)
        areaCode,
        twoFactorPhone,
        twoFactorProtocol,
        unknownRoles,
        ...validValues
      } = formattedValues;

      return validValues;
    };

    const decodeUser = (user) => {
      const defaultLanguage = {};
      const userTypes =
        this.props.userParams != null && this.props.userParams.userTypes != null
          ? this.props.userParams.userTypes
          : [];

      defaultLanguage["label"] = t("language_no");
      defaultLanguage["value"] = "NO";

      if (user == null) return { language: defaultLanguage };

      //Extact enbled as we don't wont to toggle that value here
      var { enabled, ...userParams } = user;
      var roles = {};
      let unknownRoles = undefined;
      var phoneNumbers = {};
      var language = {};
      const allUserRoleKeys = ["user", "admin"];

      if (user.roles != null) {
        Object.keys(userTypes).forEach((userType) => {
          if (user.roles.includes(userType)) {
            roles["userType"] = userTypes[userType].id;
          }
          if (userTypes[userType].userRoles != null) {
            const userRoles = userTypes[userType].userRoles;
            Object.keys(userRoles).forEach((role) => {
              const key = userRoles[role].id;
              if (!allUserRoleKeys.includes(key)) {
                allUserRoleKeys.push(key);
              }
              if (user.roles.includes(key)) {
                roles["userType"] = userTypes[userType].id;
                if (!roles[userTypes[userType].id]) {
                  roles[userTypes[userType].id] = {};
                }
                roles[userTypes[userType].id][key] = true;
              }
            });
          }
        });
      }

      const userUnknownRoles = [];
      user.roles.forEach((role) => {
        if (!allUserRoleKeys.includes(role)) {
          userUnknownRoles.push(role);
        }
      })

      if (userUnknownRoles.length > 0) {
        unknownRoles = userUnknownRoles.reduce((acc, val) => (acc + val + ","), "");
      }

      if (user.phoneNumbers) {
        user.phoneNumbers.forEach(
          (phoneNumber) =>
            (phoneNumbers[phoneNumber.phoneType.toLowerCase()] =
              phoneNumber.phone)
        );
      }

      if (user.preferedLang != null) {
        language["label"] = t("language_" + user.preferedLang.toLowerCase());
        language["value"] = user.preferedLang;
      }

      return { ...userParams, ...roles, ...phoneNumbers, language, unknownRoles };
    };

    const roleMouseOverText = (roleAbbr) => {
      if (roleAbbr.search("A") > -1) {
        return t("admin");
      } else if (roleAbbr.search("S") > -1) {
        return t("superuser");
      } else if (roleAbbr.search("G") > -1) {
        return t("user_type_corporate");
      } else if (roleAbbr.search("Y") > -1) {
        return t("user_type_issuerapi");
      } else if (roleAbbr.search("W") > -1) {
        return t("user_type_insidersyncapi");
      } else if (roleAbbr.search("X") > -1) {
        return t("user_type_newsapi");
      } else {
        return t("user");
      }
    };

    const roles = (roleAbbr) => {
      return roleAbbr
        .replace("N", "M")
        .replace("C", getLanguage() === "No" ? "S" : "C")
        .replace("U", "N");
    };

    const initialAreaCode = () => {
      let initialCode = {
        value: "NO",
        label: getLanguage() === "No" ? "Norge" : "Norway",
      };

      if (
        this.props.user &&
        this.props.user.otherProperties.twoFactorCountryCode &&
        this.props.parameterList
      ) {
        const twoFactorCode =
          this.props.user.otherProperties.twoFactorCountryCode;
        let twoFactorLabel = "";
        if (this.props.parameterList && this.props.parameterList.countries) {
          this.props.parameterList.countries.find((element) => {
            if (element.id === twoFactorCode) {
              if (getLanguage() === "No") {
                twoFactorLabel = element.nameNo;
              } else {
                twoFactorLabel = element.nameEn;
              }
            }
          });
        }
        initialCode.value = twoFactorCode;
        initialCode.label = twoFactorLabel;
      }
      return initialCode;
    };

    return (
      <FlexContainer column border padding={[10]}>
        <FlexContainer row>
          {this.props.permissions.some(
            (value) => value === "module.useradmin.all"
          ) ? (
            <PageHeading
              isFocusable
              onClick={() => {
                if (params.page !== ORGANIZATION) {
                  this.props.history.push(`${base}/${ORGANIZATION}`);
                }
              }}
              opacity={params.page === ORGANIZATION ? "1.0" : "0.5"}
              underline={params.page === ORGANIZATION}
              pointer={params.page !== ORGANIZATION}
              margin={[0, 25, 25, 0]}
              color={"#1c315b"}
            >
              {t("organisation")}
            </PageHeading>
          ) : (
            ""
          )}
          <PageHeading
            isFocusable
            opacity={
              [USERADMIN, USEREDIT].includes(params.page) ? "1.0" : "0.5"
            }
            underline={[USERADMIN, USEREDIT].includes(params.page)}
            pointer={![USERADMIN, USEREDIT].includes(params.page)}
            onClick={() => this.props.history.push(`${base}/${USERADMIN}`)}
            margin={[0, 25, 25, 0]}
            color={"#1c315b"}
          >
            {t("user_administration")}
          </PageHeading>
        </FlexContainer>

        {!this.state.loading ? (
          <Switch>
            <Route
              path={`${base}/${USERADMIN}`}
              render={() => (
                <UserAdmin
                  useServerSearch={this.props.permissions.some(
                    (value) => value === "module.useradmin.all"
                  )}
                  userSearchOnServer={(userName) => {
                    this.props.dispatch(fetchUserList(0, userName));
                  }}
                  clearUserList={() => this.props.dispatch(clearUserList())}
                  roles={roles}
                  roleMouseOverText={roleMouseOverText}
                  t={this.props.t}
                  onUserClick={onUserClick}
                  newUserAction={() => {
                    this.props.dispatch(clearOrganisation());
                    this.props.history.push(`${base}/user/`);
                    this.props.permissions.some(
                      (value) => value === "module.useradmin.all"
                    )
                      ? this.setState({ showCompanyListInUserEdit: false })
                      : this.setState({ showCompanyListInUserEdit: true });
                  }}
                  showOrgBox={this.props.permissions.some(
                    (value) => value === "module.useradmin.all"
                  )}
                  organisationList={this.props.organisationList.map((org) => {
                    return { label: org.name, value: org.id };
                  })}
                  data={this.props.userList}
                  onSelectOrganisation={(value) => {
                    if (value != null) {
                      const orgId = value.value;
                      return this.props
                        .dispatch(fetchOrganisation(orgId))
                        .then(() => this.props.dispatch(fetchUserList(orgId)));
                    }
                    return Promise.resolve();
                  }}
                  selectedOrganisation={this.props.organisation}
                />
              )}
            />

            <Route
              path={`${base}/${ORGANIZATION}`}
              render={() =>
                this.props.permissions.some(
                  (value) => value === "module.useradmin.all"
                ) ? (
                  <Organisation
                    roles={roles}
                    roleMouseOverText={roleMouseOverText}
                    newUserAction={() =>
                      this.props.history.push(`${base}/user/`)
                    }
                    onUserClick
                    toggleOrgStatus={() => {
                      return new Promise((resolve) => {
                        if (!this.props.organisation.active) {
                          return resolve(toggleOrganisationStatus());
                        } else {
                          this.setState({
                            showDeactivateOrganisationModal: true,
                            onConfirm: () => {
                              this.setState({
                                showDeactivateOrganisationModal: false,
                              });
                              return resolve(toggleOrganisationStatus());
                            },
                            onCancel: () => {
                              this.setState({
                                showDeactivateOrganisationModal: false,
                              });
                              return resolve();
                            },
                          });
                        }
                      });
                    }}
                    onFetchOrganisation={(org) =>
                      new Promise((resolve, reject) => {
                        if (org != null) {
                          this.props
                            .dispatch(fetchOrganisation(org.value))
                            .then(() =>
                              this.props.dispatch(
                                change("userAdminSearch", "organisation", org)
                              )
                            )
                            .then(() =>
                              !this.props.companyListIsFetched
                                ? this.props.dispatch(
                                    fetchCompanyList(org.value)
                                  )
                                : undefined
                            )
                            .then(() =>
                              this.props.dispatch(fetchUserList(org.value))
                            )
                            .then(() => resolve());
                        } else reject();
                      })
                    }
                    companySourceList={
                      this.props.companyList != null
                        ? this.props.companyList.map((comp) => [
                            comp.cid,
                            comp.longName,
                          ])
                        : undefined
                    }
                    companyTargetList={
                      this.props.organisation != null &&
                      this.props.organisation.companies != null
                        ? this.props.organisation.companies
                            .filter((id) => this.props.mapCompanyList.has(id))
                            .map((id) => [
                              id,
                              this.props.mapCompanyList.get(id),
                            ])
                        : undefined
                    }
                    userList={this.props.userList}
                    organisationList={this.props.organisationList
                      .filter(
                        (org) =>
                          !this.state.showOnlyActiveOrganisationsInList ||
                          org.active === true
                      )
                      .map((org) => {
                        return { label: org.name, value: org.id };
                      })}
                    organisation={this.props.organisation}
                    isActive={
                      this.props.organisation == null
                        ? true
                        : this.props.organisation.active
                    }
                    onSaveOrganisation={(orgValues, organisationAction) => {
                      if (organisationAction === OrganisationActions.EDIT) {
                        this.props.dispatch(
                          updateOrganisation(
                            orgValues,
                            () => {
                              this.props.dispatch(fetchOrganisationList());
                              NotificationManager.success(
                                t("organisation_updated")
                              );
                            },
                            () => NotificationManager.error(t("update_failed"))
                          )
                        );
                      }
                      if (
                        organisationAction === OrganisationActions.CREATE_NEW
                      ) {
                        this.props.dispatch(
                          createOrganisation(
                            orgValues,
                            () => {
                              this.props.dispatch(fetchOrganisationList());
                              NotificationManager.success(
                                t("organisation_created")
                              );
                            },
                            () => NotificationManager.error(t("create_failed"))
                          )
                        );
                      }
                    }}
                    t={this.props.t}
                    onShowActiveOrganisationChange={() => {
                      this.setState({
                        showOnlyActiveOrganisationsInList:
                          !this.state.showOnlyActiveOrganisationsInList,
                      });
                    }}
                    showOnlyActiveOrganisations={
                      this.state.showOnlyActiveOrganisationsInList
                    }
                  />
                ) : (
                  ""
                )
              }
            />

            <Route
              path={`${base}/user/:userId?`}
              render={(props) => {
                const { userId } = props.match.params;
                return (
                  <UserEdit
                    systemWideTwoFactorEnabled={
                      this.props.systemWideTwoFactorEnabled
                    }
                    t={this.props.t}
                    language={
                      i18n.language === undefined || i18n.language === "nb"
                        ? "No"
                        : "En"
                    }
                    languageOptions={
                      this.props.config != null && this.props.config.languages
                        ? this.props.config.languages.map((x) => {
                            return {
                              label: t(`language_${x}`),
                              value: x,
                            };
                          })
                        : [
                            { label: t("language_no"), value: "no" },
                            { label: t("language_en"), value: "en" },
                          ]
                    }
                    user={
                      userId != null ? decodeUser(this.props.user) : undefined
                    }
                    userEnabled={
                      this.props.user != null ? this.props.user.enabled : true
                    }
                    // If the user is an external admin (not superuser) we want to hide the role selection if he
                    // edits his own user, but we want the user type
                    // to be set to admin so that the form gets validated (in getUserType() below)
                    showSetPasswordButton={
                      this.props.permissions != null &&
                      (this.props.permissions.some(
                        (p) => p === "module.useradmin.updatePassword"
                      ) ||
                        (this.props.permissions.some(
                          (p) => p === "module.useradmin.updatePassword.api"
                        ) &&
                          this.props.userFormValues &&
                          this.props.userFormValues.userType &&
                          (this.props.userFormValues.userType === "issuerapi" ||
                            this.props.userFormValues.userType === "newsapi")))
                    }
                    showTwoFactorCheckBox={
                      this.props.permissions != null &&
                      this.props.permissions.some(
                        (p) => p === "module.useradmin.twoFactor"
                      ) &&
                      this.props.systemWideTwoFactorEnabled
                    }
                    publicKeyPermission={
                      this.props.permissions != null &&
                      this.props.permissions.some(
                        (p) => p === "module.useradmin.all"
                      )
                    }
                    getUserTypes={() =>
                      !this.props.permissions.includes(
                        "module.useradmin.all"
                      ) &&
                      userId != null &&
                      this.props.user &&
                      this.props.user.roles &&
                      this.props.user.roles.some(
                        (value) => value.toLowerCase() === UserTypes.ADMIN
                      )
                        ? undefined
                        : this.props.userParams != null
                        ? this.props.userParams.userTypes
                        : []
                    }
                    userType={this.getUserType(userId)}
                    showDeactivateButton={
                      userId != null &&
                      (this.props.permissions.some(
                        (value) => value === "module.useradmin.activateAll"
                      ) ||
                        (this.getUserType(userId) === UserTypes.USER &&
                          this.props.permissions.some(
                            (value) =>
                              value === "module.useradmin.activateRole.user"
                          )))
                    }
                    loginNamePrefix={
                      !this.props.permissions.some(
                        (value) => value === "module.useradmin.all"
                      )
                        ? this.props.userOrganisation != null
                          ? this.props.userOrganisation.loginNamePrefix
                          : ""
                        : this.props.organisation != null
                        ? this.props.organisation.loginNamePrefix
                        : ""
                    }
                    organisationList={
                      this.props.organisationList != null
                        ? this.props.organisationList.map((org) => {
                            return {
                              value: { id: org.id, isInternal: org.internal },
                              label: org.name,
                            };
                          })
                        : undefined
                    }
                    onSelectOrganisation={(org) => {
                      if (org != null && org.value != null) {
                        this.props
                          .dispatch(fetchOrganisation(org.value.id))
                          .then(() =>
                            this.props.dispatch(fetchUserList(org.value.id))
                          )
                          .then(() =>
                            this.props.dispatch(
                              change("userAdminSearch", "organisation", org)
                            )
                          )
                          .then(() =>
                            this.setState({ showCompanyListInUserEdit: true })
                          );
                      }
                    }}
                    // Have to sendt in the orgId so that the UserEdit component can check if
                    // the org has changed as it does not check for object equality
                    organisationId={
                      this.props.organisation
                        ? this.props.organisation.id
                        : this.props.userOrganisation
                        ? this.props.userOrganisation.id
                        : undefined
                    }
                    organisation={
                      this.props.organisation
                        ? this.props.organisation
                        : this.props.userOrganisation
                        ? this.props.userOrganisation
                        : undefined
                    }
                    preSelectOrganisation={
                      !this.props.permissions.some(
                        (value) => value === "module.useradmin.all"
                      ) || userId != null
                    }
                    disableOrganisationList={
                      !this.props.permissions.some(
                        (value) => value === "module.useradmin.all"
                      )
                    }
                    editTwoFactor={this.props.permissions.some(
                      (value) => value === "module.useradmin.twoFactor"
                    )}
                    companyList={
                      this.state.showCompanyListInUserEdit &&
                      this.props.organisation != null &&
                      this.props.organisation.companies != null
                        ? this.props.companyList.filter(comp => this.props.organisation.companies.includes(comp.cid)).map(comp => comp.longName)
                        : undefined
                    }
                    action={userId != null ? userAction.EDIT : userAction.NEW}
                    onCancel={() => {
                      this.props.dispatch(destroy("userForm"));
                      this.props.history.length > 0
                        ? this.props.history.goBack()
                        : this.props.history.push(`${props.base}`);
                    }}
                    onToggleUserActivity={(orgId) => {
                      const user = this.props.user;

                      const toggleUser = () => {
                        return user.enabled
                          ? this.props
                              .dispatch(deactivateUser(user.id))
                              .catch((error) => console.log(error))
                          : this.props
                              .dispatch(activateUser(user.id))
                              .catch((error) => console.log(error));
                      };

                      toggleUser()
                        .then(() => this.props.dispatch(fetchUser(user.id)))
                        .then(() => this.props.dispatch(fetchUserList(orgId)))
                        .then(() =>
                          NotificationManager.success(t("user_updated"))
                        )
                        .catch((error) => console.log(error));
                    }}
                    onSave={() => {
                      const editExistingUser = userId != null;
                      const createNewUser = userId == null;

                      const adminRoleIsSelected =
                        this.props.userFormValues.userType === UserTypes.ADMIN;

                      const changedUserRoleToAdmin =
                        userId != null &&
                        this.props.user.roles &&
                        !this.props.user.roles.some(
                          (role) => role === UserTypes.ADMIN
                        ) &&
                        this.props.userFormValues.userType === UserTypes.ADMIN;

                      const insiderAdminIsSelected =
                        this.props.userFormValues[UserTypes.ADMIN] != null &&
                        this.props.userFormValues[UserTypes.ADMIN].insider;

                      if (
                        (editExistingUser && changedUserRoleToAdmin) ||
                        (createNewUser && adminRoleIsSelected)
                      )
                        this.setState({
                          showAdminCreationModal: true,
                          insider_admin: insiderAdminIsSelected,
                        });
                      else this.props.dispatch(submit("userForm"));
                    }}
                    onSubmit={(user, dispatch, props) => {
                      const sendUserDataToServer = (userValues) => {
                        if (userValues.id != null) {
                          return dispatch(updateUser(userValues));
                        } else return dispatch(createUser(userValues));
                      };
                      const updateUserList = (userValues) => () =>
                        dispatch(fetchUserList(userValues.organisationId));

                      const showNotification = () => {
                        NotificationManager.success(
                            !!user.id
                                ? props.t("user_updated")
                                : props.t("user_created")
                        );
                      }

                      const goBackToUserAdmin = () =>
                        this.props.history.length > 0
                          ? this.props.history.goBack()
                          : this.props.history.push(
                              `${props.base}/${USERADMIN}`
                            );
                      const handleServerErrors = (error, reject) => {
                        if (error.data && error.data.details) {
                          if (error.data.details.some((e) => e.code === 1210))
                            return reject(
                              new SubmissionError({
                                username: props.t("username_in_use"),
                              })
                            );
                          if (error.data.details.some((e) => e.code === 1018))
                            return reject(
                              new SubmissionError({
                                mobile: props.t("invalid_phonenumber"),
                              })
                            );
                          else {
                            error.data.details.forEach((e) =>
                              NotificationManager.error(e.message, "", 0)
                            );
                          }
                        } else
                          NotificationManager.error(
                            t("an_error_occured"),
                            "",
                            0
                          );

                        return reject(new SubmissionError({}));
                      };

                      return new Promise((resolve, reject) => {
                        const errors = userFormValidator(user, props);

                        if (errors) {
                          return reject(new SubmissionError(errors));
                        }

                        const userValues = encodeUser(user);
                        const allAuthValuesFalse = allAuthValuesAreFalse(
                          userValues,
                          props
                        );
                        if (allAuthValuesFalse) {
                          return reject(
                            new SubmissionError(allAuthValuesFalse)
                          );
                        }
                        sendUserDataToServer(userValues)
                          .then(showNotification)
                          .then(goBackToUserAdmin)
                          .then(updateUserList(userValues))
                          .then(resolve)
                          .catch((error) => handleServerErrors(error, reject));
                      });
                    }}
                    handlePasswordReset={(password, props) => {
                      const errors = emailValidator(password, props);

                      return new Promise((resolve, reject) => {
                        if (errors) {
                          return reject(new SubmissionError(errors));
                        }
                        this.props
                          .dispatch(
                            resetUserPassword(this.props.user.globalSubject)
                          )
                          .then(() =>
                            this.props.history.length > 0
                              ? this.props.history.goBack()
                              : this.props.history.push(
                                  `${props.base}/${USERADMIN}`
                                )
                          )
                          .then(() => {
                            NotificationManager.success(
                              t("password_reset_sent")
                            );
                          })
                          .catch((error) => console.log(error));
                      });
                    }}
                    parameterList={this.props.parameterList}
                    initialAreaCode={initialAreaCode()}
                    hiddenRoles={this.props.publicProps
                      .filter(
                        (prop) =>
                          prop.key.startsWith("global.module") &&
                          prop.value === "false"
                      )
                      .map((prop) =>
                        prop.key.substring(prop.key.lastIndexOf(".") + 1)
                      )}
                    userParams={this.props.userParams}
                    dispatch={this.props.dispatch}
                    clearValidKeys={() => this.props.dispatch(clearValidKeys())}
                    validKeys={this.props.validKeys}
                    clearApiKeys={() => this.props.dispatch(clearApiKeys())}
                    apiKeys={this.props.apiKeys}
                  />
                );
              }}
            />

            <Redirect to={`${this.props.base}/${USERADMIN}`} />
          </Switch>
        ) : (
          <Loader />
        )}
        <ConfirmModal
          center
          header={t("admin_creation")}
          isOpen={this.state.showAdminCreationModal}
          onClose={() => this.setState({ showAdminCreationModal: false })}
          confirmText={"OK"}
          cancelText={t("cancel")}
          onConfirm={() => {
            this.setState({
              showAdminCreationModal: false,
              insider_admin: false,
            });
            this.props.dispatch(submit("userForm"));
          }}
        >
          <p>{t("admin_creation_confirm")}</p>
          {this.state.insider_admin ? <p>{t("admin_creation_insider")}</p> : ""}
          {this.state.insider_admin ? (
            <p>{t("admin_creation_insider_obnt")}</p>
          ) : (
            ""
          )}
        </ConfirmModal>
        <ConfirmModal
          center
          header={t("deactivate_organisation")}
          isOpen={this.state.showDeactivateOrganisationModal}
          onClose={() =>
            this.setState({ showDeactivateOrganisationModal: false })
          }
          confirmText={"OK"}
          cancelText={t("cancel")}
          onConfirm={this.state.onConfirm}
          onClose={this.state.onCancel}
        >
          <p>{t("deactivate_organisation_confirm")}</p>
        </ConfirmModal>
      </FlexContainer>
    );
  }
}

AdminContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
  const {
    adminReducer,
    companyReducer,
    permissionReducer,
    form,
    loginUser,
    companyInformationReducer,
    systemAdministratorReducer,
    config,
  } = state;

  return {
    organisationList: adminReducer.organisationList.list,
    organisationListIsFetched: adminReducer.organisationList.isFetched,

    organisation: adminReducer.organisation.orgData,
    organisationIsFetched: adminReducer.organisation.organisationIsFetched,

    userList: adminReducer.userList.list,
    user: adminReducer.user.userData,

    userParams: adminReducer.userParams.params,
    systemWideTwoFactorEnabled:
      adminReducer.userParams.params &&
      adminReducer.userParams.params.authenticationParams !== null &&
      adminReducer.userParams.params.authenticationParams
        .systemWideTwoFactorEnabled !== null &&
      adminReducer.userParams.params.authenticationParams
        .systemWideTwoFactorEnabled === "true",
    userParamsAreFetched: adminReducer.userParams.paramsAreFetched,
    userIsFetched: adminReducer.user.userIsFetched,

    userCreated: adminReducer.user,
    validKeys: adminReducer.validKeys.list,
    apiKeys: adminReducer.apiKeys.list,

    companyListIsFetched: companyReducer.companyListIsFetched,
    companyList: companyReducer.list,
    mapCompanyList:
      companyReducer.list != null
        ? new Map(companyReducer.list.map((comp) => [comp.cid, comp.longName]))
        : new Map(),

    permissions: permissionReducer.permissions || [],

    userFormValues: form.userForm ? form.userForm.values : [],
    passwordFormValues: form.passwordForm ? form.passwordForm.values : [],

    userOrganisation: loginUser.userOrganisation,

    parameterList: companyInformationReducer.parameters.list,
    publicProps: systemAdministratorReducer.publicProps.list || {},
    config: config.config,
  };
}

export default withRouter(
  connect(mapStateToProps)(withTranslation("translations")(AdminContainer))
);
