import React from "react";
import {Modal} from "../components/Modal";
import {FlexContainer} from "../components/FlexContainer";
import {Field, reduxForm} from "redux-form";
import Textarea from "../components/Textarea";
import Button from "../components/Button";
import {PageHeading} from "../components/PageHeading";
import DayPicker from "../components/DayPicker";
import {InputBox} from "../innsider/InsiderList";
import moment from "moment/moment";
import {getPrettyOBNTDate, hasValue} from "../util/common";
import {ErrorText} from "../components/ErrorText";
import Table, {TD, TR} from "../components/Table";
import Input, {StyledInput} from "../components/Input";
import Label from "../components/Label";
import {deleteApiKey, generateApiKey, getApiKeys} from "./AdminActions";
import styled from "styled-components";
import {NotificationManager} from "react-notifications";

export const SuccessText = styled.p`
    color: green;
    font-weight: 600;
`;

const IconOnInput = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  padding: 4px;
  position: absolute;
  box-sizing:border-box;
  ${props => (props.top ? "top: 15px;" : "top: 50%;")}
  right:2px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const InputCopy = props => {
    const icon = props.copied ? "/icons/success.png" : "/icons/copy.png";
    return (
        <FlexContainer>
            {props.label &&
                <Label bold marginTop={props.labelMargin ? props.labelMargin : "0"}>{props.label} </Label>}
            <FlexContainer relative>
                <StyledInput border autoComplete="off" {...props.input} {...props} />
                {(props.defaultValue || props.value) &&
                    <IconOnInput
                        onClick={() => props.onCopy ? props.onCopy(props.name) : undefined}
                        src={icon}
                    />
                }
            </FlexContainer>
        </FlexContainer>
)};

const TextAreaCopy = props => {
    const icon = props.copied ? "/icons/success.png" : "/icons/copy.png";
    return (
        <FlexContainer>
            {props.label && <Label
                bold
                margin={[10, 0, 0, 0]}
            >
                {props.label}
            </Label>}
            <FlexContainer relative>
                <Textarea  border readOnly textAreaCopy {...props} />
                {props.value &&
                    <IconOnInput
                        onClick={() => props.onCopy ? props.onCopy(props.name) : undefined}
                        src={icon}
                        top
                    />
                }
            </FlexContainer>
        </FlexContainer>
    )};

export const validatePublicKey = (values, t) => {
    const errors = {};
    if (values === undefined) values = {}
    if (values.expires && !(
            moment(values.expires, "DD.MM.YYYY", true).isValid() ||
            moment(values.expires, "YYYY-MM-DD", true).isValid())) {
        errors.expires = t("invalid_date");
    }
    if (!values.authorizedKey && !values.isUpdate) {
        errors.authorizedKey = t("required_field")
    }

    return Object.keys(errors).length > 0 ? errors : undefined;
}

class ApiKeys extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedKey: undefined,
            delete: undefined
        };
    }

    componentDidMount() {
        this.selectFirstKeyOnAnyChange()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.selectFirstKeyOnAnyChange()
    }

    selectFirstKeyOnAnyChange() {
        if (this.state.selectedKey === undefined && this.props.apiKeys.length > 0) {
            this.setState({selectedKey: this.props.apiKeys[0]})
        }
    }

    onDeleteKey = (e) => {
        const showNotification = () => {
            NotificationManager.success(this.props.t("key_deleted"));
        }
        this.props.dispatch(deleteApiKey(this.props.user.globalSubject, e.id))
            .then(showNotification)
            .then(() => this.props.dispatch(getApiKeys(this.props.user.globalSubject)))
            .then(() => {this.setState({delete: undefined, selectedKey: undefined})})
            .catch((err) => console.log(err));
    }

    render() {
        const { t } = this.props;
        const thLabels = [t("key"), t("key_desc"), t("created"), t("valid_to"), t("delete")];
        const tableRows =  this.props.apiKeys.map((key, index) =>
            <TR key={index}>
                <TD pointer underline={this.state.selectedKey === this.props.apiKeys[index]}
                    onClick={() =>
                        this.setState({selectedKey: this.props.apiKeys[index],
                            delete: undefined})}
                >
                    {this.props.apiKeys[index].apiKey}
                </TD>
                <TD>
                    {this.props.apiKeys[index].description}
                </TD>
                <TD>
                    {getPrettyOBNTDate(this.props.apiKeys[index].created)}
                </TD>
                <TD>
                    {getPrettyOBNTDate(this.props.apiKeys[index].expires)}
                </TD>
                <TD pointer={this.state.selectedKey === this.props.apiKeys[index]} center>
                    { this.state.selectedKey === this.props.apiKeys[index] &&
                        <img
                            alt={"Delete symbol"}
                            src="/icons/red-cross-24.svg"
                            onClick={() => this.setState({delete: this.state.selectedKey})}
                        />
                    }

                </TD>
            </TR>
        );

        return (
                <FlexContainer column margin={[0, 0, 16]}>
                    <FlexContainer row>
                        <FlexContainer margin={[0, 15, 0, 0]}>
                            <Table
                                tableRows={tableRows}
                                tableHeaderLabels={thLabels}
                                forceUpdate={true}
                            />
                        </FlexContainer>
                    </FlexContainer>
                    <FlexContainer flexEnd vAlignCenter row margin={[30, 0, 0, 0]}>
                        {hasValue(this.state.delete) &&
                            <FlexContainer>
                                <FlexContainer>
                                    <p>{t("key_delete_are_you_sure")}</p>
                                    <FlexContainer row vAlignCenter>
                                        <Button.Standard
                                            margin={[0, 16, 0, 0]}
                                            onClick={() => this.onDeleteKey(this.state.delete)}
                                        >
                                            {t("delete")}
                                        </Button.Standard>
                                        <Button.Text
                                            onClick={() => this.setState({delete: undefined})}
                                        >
                                            {t("cancel")}
                                        </Button.Text>
                                    </FlexContainer>
                                </FlexContainer>
                            </FlexContainer>}
                        <Button.Text success
                            onClick={this.props.onCloseKeyManagementModal}
                        >
                            {t("done")}
                        </Button.Text>
                    </FlexContainer>
                </FlexContainer>
        );
    }
}

class ValidKeys extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedKey: undefined,
            keyToChange: undefined,
            errorNoChange: false,
            edit: false,
            errors: undefined
        };
    }

    componentDidMount() {
        this.selectFirstKeyOnAnyChange()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.selectFirstKeyOnAnyChange()
    }

    selectFirstKeyOnAnyChange() {
        if (this.state.selectedKey === undefined && this.props.validKeys.length > 0) {
            this.setState({selectedKey: this.props.validKeys[0]})
        }
    }

    setNewDate = (date) => {
        const selected = JSON.parse(JSON.stringify(this.state.selectedKey));
        selected.expires = date;
        this.setState({keyToChange: selected})
    }

    onUpdateKey = () => {
        if (this.state.keyToChange === undefined) {
            this.setState({errorNoChange: true})
        } else {
            const errors = validatePublicKey(this.state.keyToChange, this.props.t);
            if (errors) {
                this.setState({errors: errors})
            } else {
                let sendObj = {};
                sendObj.id = this.state.keyToChange.id;
                sendObj.expires = this.state.keyToChange.expires;
                sendObj.isUpdate = true;
                this.props.handleSubmit(this.props.onSubmitPublicKey(sendObj))
            }
        }
    }

    render() {
        const { t } = this.props;
        const thLabels = [t("expires")];
        const tableRows =  this.props.validKeys.map((key, index) =>
            <TR key={index}>
                <TD boldFirstLine pointer
                    onClick={() =>
                        this.setState({selectedKey: this.props.validKeys[index],
                            keyToChange: undefined, errorNoChange: false, edit: false, errors: undefined})}
                >
                    {getPrettyOBNTDate(this.props.validKeys[index].expires) || t("no_expiration_date")}
                </TD>
            </TR>
        );

        return (
            <form autoComplete="off">
            <FlexContainer column margin={[0, 0, 16]}>
                <FlexContainer row>
                    <FlexContainer margin={[0, 15, 0, 0]}>
                        <Table
                            tableRows={tableRows}
                            tableHeaderLabels={thLabels}
                            forceUpdate={true}
                            noHeader={true}
                        />
                    </FlexContainer>
                    <FlexContainer>
                        {this.state.selectedKey !== undefined ? (
                            <FlexContainer>
                                {this.state.edit ?
                                    <FlexContainer margin={[0, 0, 5, 0]}>
                                        {this.state.errors && this.state.errors.expires ?
                                            <ErrorText>{this.state.errors.expires}</ErrorText> : undefined}
                                    <Field
                                        name={"expires"}
                                        component={DayPicker}
                                        border
                                        input={{
                                            value: this.state.selectedKey.expires !== undefined ?
                                                getPrettyOBNTDate(this.state.selectedKey.expires) :
                                                this.state.keyToChange && this.state.keyToChange.expires !== undefined ?
                                                    getPrettyOBNTDate(this.state.keyToChange.expires) : undefined,
                                            onChange: date => {
                                                if (date) {
                                                    this.setNewDate(date);
                                                }
                                            }
                                        }}
                                        disableDaysBefore={new Date()}
                                        numFutureYears={2}
                                        numPreviousYears={0}
                                    /> </FlexContainer>
                                        : <Input value={this.state.selectedKey.expires !== undefined ?
                                        getPrettyOBNTDate(this.state.selectedKey.expires) :
                                        this.state.keyToChange && this.state.keyToChange.expires !== undefined ?
                                            getPrettyOBNTDate(this.state.keyToChange.expires) : ""}
                                                disabled border
                                                margin={[0, 0, 5, 0]}/>
                                }
                                <Field
                                    border
                                    name="authorizedKey"
                                    component={Textarea}
                                    placeholder={this.state.selectedKey.authorizedKey}
                                    type="text"
                                    greyPlaceholder
                                    disabled
                                    input={this.state.selectedKey.authorizedKey}
                                    minHeight={"300px"}
                                />
                                {this.state.edit ? <FlexContainer>
                                    <FlexContainer flexEnd vAlignCenter row margin={[30, 0, 0, 0]}>
                                        {this.state.errorNoChange && (<ErrorText >{t("no_changes")}</ErrorText>)}
                                        <Button.Standard
                                            margin={[0, 16, 0, 0]}
                                            onClick={this.onUpdateKey}
                                        >
                                            {t("update")}
                                        </Button.Standard>
                                        <Button.Text
                                            onClick={() => this.setState({edit: false, keyToChange: undefined, errors: undefined})}
                                        >
                                            {t("cancel")}
                                        </Button.Text>
                                    </FlexContainer>
                                </FlexContainer> :
                                <FlexContainer flexEnd vAlignCenter row margin={[30, 0, 0, 0]}>
                                    <Button.Standard
                                    margin={[0, 16, 0, 0]}
                                    onClick={() => this.setState({edit: true})}
                                >
                                    {t("edit")}
                                </Button.Standard>
                                </FlexContainer>}

                            </FlexContainer>
                                ) : ""}
                    </FlexContainer>

                </FlexContainer>

            </FlexContainer>
            </form>
        );
    }
}

ValidKeys = reduxForm({
    form: "ValidKeys",
})(ValidKeys);

class GenerateKeyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: undefined,
            generatedKey: undefined,
            copied: false
        }
    }

    generateKey = (val) => {
        this.props.dispatch(generateApiKey(this.props.user.globalSubject, val))
            .then((res) => {
                this.setState({generatedKey: res.data.entity})
            })
            .then(() => {
                this.props.dispatch(getApiKeys(this.props.user.globalSubject))
            })
    }

    copyValue = (field) => {
        const textToCopy = field === undefined ? this.state.generatedKey : this.state.generatedKey[field];
        navigator.clipboard.writeText(JSON.stringify(textToCopy))
            .then(() => {this.setState({copied: field === undefined ? true : field})});
        setTimeout(function(){
            this.setState({copied: undefined});
        }.bind(this),700);
    }

    render() {
        const { t } = this.props;

        return (
            <form autoComplete="off">
                <FlexContainer>
                    <FlexContainer row spaceBetween>
                        <FlexContainer width={"30%"} >
                            <p>{t("api_key_generate_info")}</p>
                            <FlexContainer row margin={[30, 0, 0, 0]}>
                                <Button.Standard onClick={this.props.handleSubmit(this.generateKey)}>{t("generate_key")}</Button.Standard>
                                {hasValue(this.state.generatedKey) &&
                                    <Button.Standard margin={[0, 0, 0, 10]}
                                                     inverted
                                                     onClick={this.copyValue}>
                                        {t("copy_everything")}
                                    </Button.Standard>
                                }
                            </FlexContainer>
                            {this.state.copied === true && <SuccessText>{t("copied")}</SuccessText>}


                        </FlexContainer>

                        <FlexContainer width={"50%"} FlexEnd>
                            <Field component={InputCopy}
                                   name="description"
                                   label={t("key_desc")}
                                   placeholder={"Beskrivelse av nøkkelen..."}
                                   greyPlaceholder
                                   readOnly={hasValue(this.state.generatedKey)}
                                   onCopy={(e) => this.copyValue(e)}
                                   copied={this.state.copied === "description"}
                                   defaultValue={hasValue(this.state.generatedKey) ? this.state.generatedKey.description : undefined}
                            />

                            <InputCopy
                                name="apiKey"
                                readOnly
                                labelMargin={"8"}
                                disabled={!hasValue(this.state.generatedKey)}
                                label={t("api_key")}
                                onCopy={(e) => this.copyValue(e)}
                                copied={this.state.copied === "apiKey"}
                                defaultValue={hasValue(this.state.generatedKey) ? this.state.generatedKey.apiKey : ""}
                            />

                            <TextAreaCopy
                                    name="apiSecret"
                                    label={t("Api Secret")}
                                    onCopy={(e) => this.copyValue(e)}
                                    disabled={!hasValue(this.state.generatedKey)}
                                    copied={this.state.copied === "apiSecret"}
                                    value={hasValue(this.state.generatedKey) ? this.state.generatedKey.apiSecret : ""}
                                    minHeight={"150px"}
                            />

                        </FlexContainer>

                    </FlexContainer>

                    <FlexContainer flexEnd vAlignCenter row margin={[50, 0, 0, 0]}>
                        <Button.Text success
                            onClick={this.props.onCloseKeyManagementModal}
                        >
                            {t("done")}
                        </Button.Text>
                    </FlexContainer>
                </FlexContainer>
            </form>)
    }
}

GenerateKeyForm = reduxForm({
    form: "generateKeyForm",
})(GenerateKeyForm);

class PublicKeyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: undefined
        }
    }

    onSubmitKey = (values) => {
        const sanitizePublicKey = (value) => {
            value = value.replace("-----BEGIN PUBLIC KEY-----", "");
            value = value.replace("-----END PUBLIC KEY-----", "");
            value = value.replace(/(\r\n|\n|\r|\s)/gm, "");
            return value;
        }
        const errors = validatePublicKey(values, this.props.t);
        if (errors) {
            this.setState({errors: errors})
            return errors
        } else {
            values.authorizedKey = sanitizePublicKey(values.authorizedKey);
            this.props.onSubmitPublicKey(values)
        }
    }

    render() {
        const { t } = this.props;

        return (
            <form autoComplete="off">
            <FlexContainer>
                <FlexContainer row spaceBetween>
                    <FlexContainer width={"60%"} >
                        <p><b>{t("convert_key")}</b>
                            ssh-keygen -f example_key.pub -e -m pkcs8</p>
                        {this.state.errors && this.state.errors.password ?
                            <ErrorText>{this.state.errors.password}</ErrorText> : undefined}
                        <Field
                            border
                            name="authorizedKey"
                            component={Textarea}
                            placeholder={t("paste_here")}
                            type="text"
                            greyPlaceholder
                            minHeight={"300px"}
                        />
                    </FlexContainer>

                    <FlexContainer width={"30%"} FlexEnd margin={[30, 10, 0, 0]}>
                        {this.state.errors && this.state.errors.expires ?
                            <ErrorText>{this.state.errors.expires}</ErrorText> : undefined}
                        <InputBox
                            border
                            label={t("valid_to")}
                            name="expires"
                            component={DayPicker}
                            numFutureYears={5}
                            keepValueOnBlurOrEnter
                            disableDaysBefore={new Date()}
                        />
                    </FlexContainer>

                </FlexContainer>

                <FlexContainer flexEnd vAlignCenter row margin={[50, 0, 0, 0]}>
                    <Button.Standard
                        margin={[0, 16, 0, 0]}
                        onClick={this.props.handleSubmit(this.onSubmitKey)}
                    >
                        {t("save")}
                    </Button.Standard>
                    <Button.Text
                        onClick={this.props.onCloseKeyManagementModal}
                    >
                        {t("cancel")}
                    </Button.Text>
                </FlexContainer>
        </FlexContainer>
            </form>)
    }
}

PublicKeyForm = reduxForm({
    form: "publicKeyForm",
})(PublicKeyForm);

class ApiKeyManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddKeyForm: false
        };
    }

    render() {
        return (
            <Modal
                isOpen={this.props.openKeyManagementModal}
                onClose={this.props.onCloseKeyManagementModal}
            >
                <FlexContainer scrollbarsOnOverflow >
                    <FlexContainer vAlignCenter row >
                        <PageHeading
                            isFocusable
                            onClick={() => this.setState({showAddKeyForm: false})}
                            opacity={!this.state.showAddKeyForm ? "1.0" : "0.5"}
                            underline={!this.state.showAddKeyForm}
                            pointer={this.state.showAddKeyForm}
                            margin={[0, 25, 25, 0]}
                            color={"#1c315b"}
                        >
                            {this.props.t("valid_keys")}
                        </PageHeading>
                        <PageHeading
                            isFocusable
                            onClick={() => this.setState({showAddKeyForm: true})}
                            opacity={this.state.showAddKeyForm ? "1.0" : "0.5"}
                            underline={this.state.showAddKeyForm}
                            pointer={!this.state.showAddKeyForm}
                            margin={[0, 25, 25, 0]}
                            color={"#1c315b"}
                        >
                            {this.props.t("set_key")}
                        </PageHeading>
                    </FlexContainer>
                    {this.props.apiRole === "newsapi" ?
                        <FlexContainer>
                            {this.state.showAddKeyForm ? <PublicKeyForm {...this.props} /> :
                                <ValidKeys validKeys={this.props.validKeys} {...this.props} />
                            }
                        </FlexContainer>
                        :
                        <FlexContainer>
                            {this.state.showAddKeyForm ? <GenerateKeyForm {...this.props} /> :
                                <ApiKeys apiKeys={this.props.apiKeys} {...this.props} />
                            }
                        </FlexContainer>
                    }

                </FlexContainer>
            </Modal>
        );
    }
}

export default ApiKeyManagement;