import React from "react";
import FirstForm from "./FormOne";
import FormTwo from "./FormTwo";
import NewsReceipt from "./NewsReceipt";
import { Redirect, Route, Switch } from "react-router-dom";
import moment from "moment-timezone";
import { change, destroy } from "redux-form";
import { connect } from "react-redux";
import {
  clearSecurities,
  createNewsMessage,
  fetchCategories,
  fetchDraftMessageList,
  fetchMessageList,
  fetchSecurities,
  removeAttachment,
  removeNewsMessage,
  resetMessageData,
  updateNewsMessage,
} from "./MessageActions";
import { NewsMessageAction, uploadFile } from "./NewsMessageContainer";
import { FileUploadObjectType } from "../components/FileUploader";
import { FlexContainer } from "../components/FlexContainer";
import { HelpModal } from "../components/HelpModal";
import { getLanguage, OBNTDateTimeFormat } from "../util/common";
import NotificationManager from "react-notifications/lib/NotificationManager";
import i18next from "i18next";
import { fetchCompany } from "../companyInformation/CompanyInformationActions";
import styled from "styled-components";
export const FORMONE = "message";
const FORMTWO = "confirm";
const NEWSRECIEPT = "reciept";

export const NewsPublishTimeType = {
  NOW: "now",
  FUTURE: "future",
};

const ErrorText = styled.span`
  white-space: ${(props) => (props.err500 ? "normal;" : "pre-wrap;")};
  word-wrap: break-word;
`;

class EditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.createInitalValuesFormOne = this.createInitalValuesFormOne.bind(this);
    this.createInitalValuesFormTwo = this.createInitalValuesFormTwo.bind(this);
    this.sendToServer = this.sendToServer.bind(this);

    const timeZoneNames = moment.tz.names();
    const timeZone = moment.tz.guess();

    this.state = {
      showHelpModal: false,
      helpModalText: undefined,
      helpModalHeaderText: undefined,
      obdnConfig: undefined,
      templateList: [],
      timeZoneNames: timeZoneNames.map((timeZone) => {
        return {
          value: timeZone,
          label: timeZone,
          helpText: timeZone.substring(timeZone.indexOf("/") + 1),
        };
      }),
      userTimeZone: timeZone,
      selectedDistributionLists: [],
      selectedTemplateCategory: undefined,
      overlayLoader: false
    };
  }

  removeInfoOnEsc = (e) => {
    if (e.keyCode === 27) {
      this.closeHelpModal();
    }
  };

  componentWillMount() {
    window.addEventListener("keydown", this.removeInfoOnEsc);

    switch (this.props.newsMessageAction) {
      case NewsMessageAction.EDIT:
      case NewsMessageAction.CORRECTION:
        const category = this.props.categoryList.find(
          (category) => category.id === this.props.newsMessage.category.id
        );

        let selectedDistributionList = [];
        if (
          this.props.newsMessage &&
          this.props.newsMessage.distributionLists &&
          this.props.distributionList
        ) {
          this.props.newsMessage.distributionLists.map((listId) => {
            const list = this.props.distributionList.find(
              (list) => list.id === listId
            );
            if (list) {
              selectedDistributionList.push({
                value: listId,
                label: list.name,
              });
            }
          });
        }

        this.setState({
          obdnConfig: category ? category.categoryConfig : undefined,
          selectedDistributionLists: selectedDistributionList,
        });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.removeInfoOnEsc);
  }

  createInitalValuesFormTwo = (message) => {
    var formatedValues = {};
    const messageIsPublished = (time) => time != null && moment().isAfter(time);

    if (
      message != null &&
      !messageIsPublished(message.publishedTime) &&
      message.publishedTime != null
    ) {
      formatedValues.pDate = moment(
        message.publishedTime,
        "YYYY-MM-DDTHH:mmZ"
      ).format("DD-MM-YYYY");
      formatedValues.pTime = moment(
        message.publishedTime,
        "YYYY-MM-DDTHH:mmZ"
      ).format("HH:mm");
      formatedValues.publishTime = NewsPublishTimeType.FUTURE;
    }

    return formatedValues;
  };

  userLanguage() {
    return getLanguage();
  }

  getCurrentCategoryId = (message) => {
    //Some sort of fix for OBNT-2007. onSelectTemplate -> sets a state of categoryID if templates are in use, if not, its always undefined.
    //This can be confusing, since the values sent to backend is'nt always fetched from the form.
    //might be solved in a better way...
    //Currently using ID from state if specialAgreement,
    //if correction and special agreement and category not selected from user, then use category from id from message object recived from server.
    //else use category from form

    if (
      this.state.selectedTemplateCategory == null &&
      message.category != null
    ) {
      return message.category.value;
    }
    if (this.state.selectedTemplateCategory != null) {
      return this.state.selectedTemplateCategory;
    } else {
      return this.props.message.category.id;
    }
  };

  createInitalValuesFormOne = (message) => {
    var formatedValues = undefined;
    if (
      message != null &&
      this.props.securities != null &&
      this.props.companyList != null
    ) {
      formatedValues = {};

      const instrument = this.props.securities.find(
        (sec) => sec.sid === message.sid
      );
      const company = this.props.companyList.find(
        (comp) => comp.cid === message.cid
      );

      formatedValues.id = message.id;
      formatedValues["distributionRequest"] = message.distributionRequest;
      formatedValues["title_" + message.language.toLowerCase()] = message.title;
      formatedValues["message_" + message.language.toLowerCase()] =
        message.body;

      formatedValues.category =
        message.category != null
          ? {
              value: message.category.id,
              label: message.category["name" + this.userLanguage()],
            }
          : undefined;
      if (this.state.obdnConfig && this.state.obdnConfig.scenario) {
        if (this.state.obdnConfig.scenario === "specialAgreement") {
          formatedValues.template = {
            value: message.category.id,
            label: message.category["name" + this.userLanguage()],
          };
        }
      }

      formatedValues.instrument =
        instrument != null
          ? { value: message.sid, label: instrument.symbol }
          : undefined;
      formatedValues.issuer =
        company != null
          ? { value: message.cid, label: company.longName }
          : undefined;
      formatedValues["attachments_" + message.language.toLowerCase()] =
        this.props.attachmentList != null
          ? this.props.attachmentList.map((att) => {
              return { fileId: att.attId, fileName: att.attName };
            })
          : undefined;
    }
    return formatedValues;
  };

  sendToServer = (message, messageStatus) => {
    const setPublishedTime = () => {
      if (this.props.publishTimeType === NewsPublishTimeType.FUTURE) {
        message["publishedTime"] = moment
          .tz(
            this.props.publishDate + " " + this.props.publishTime,
            OBNTDateTimeFormat,
            this.props.config.localDateTime
              ? this.props.publishTimeZone.value
              : "Europe/Oslo"
          )
          .toDate()
          .toISOString();
      }
    };

    const createMessageObject = (message, lng) => {
      let formattedValues = undefined;
      if (
        message["title_" + lng] != null &&
        message["message_" + lng] != null
      ) {
        formattedValues = {};
        formattedValues["title"] = message["title_" + lng];
        formattedValues["body"] = message["message_" + lng];
        formattedValues["cid"] = message.issuer.value;
        formattedValues.id = message.id;
        formattedValues["category"] = {
          id: this.getCurrentCategoryId(message),
        };
        formattedValues["language"] = lng;
        formattedValues["messageStatus"] = message.messageStatus;
        formattedValues["distributionRequest"] = message.distributionRequest;
        formattedValues["publishedTime"] = message.publishedTime;

        if (message.instrument != null) {
          formattedValues["sid"] = message.instrument.value;
        }
        formattedValues["messageStatus"] = "DRAFT";
        formattedValues["test"] = message.test;
      }
      return formattedValues;
    };

    const handleAttachments = (lng, newsId) => {
      if (message["attachments_" + lng] != null) {
        return Promise.all(
          message["attachments_" + lng]
            .filter(
              (fileObj) =>
                fileObj.type === FileUploadObjectType.DELETED_SERVER_FILE
            )
            .map((fileObj) =>
              this.props.dispatch(removeAttachment(fileObj.fileId))
            )
        ).then(() => {
          return new Promise((resolve, reject) => {
            message["attachments_" + lng]
              .filter((fileObj) => fileObj.type === FileUploadObjectType.LOCAL)

              .map(
                (fileObj) =>
                  new Object({
                    fileObj,
                    upload: () =>
                      uploadFile(fileObj, newsId, this.props.dispatch),
                  })
              )

              .reduce(
                (
                  acc,
                  val /* Loop over all files, upload them, and return a list of ids that has been uploaded */
                ) =>
                  acc.then((prevFileIds) => {
                    return val
                      .upload()
                      .then((fileId) => prevFileIds.concat(fileId))
                      .catch(() => {
                        throw prevFileIds;
                      });
                  }),
                Promise.resolve([])
              )
              .then(resolve)
              .catch((uploadedFileIds) => {
                /* Rollback file uploads */
                uploadedFileIds.forEach((fileId) =>
                  this.props.dispatch(removeAttachment(fileId))
                );

                return reject("File upload failed");
              });
          });
        });
      } else {
        return Promise.resolve();
      }
    };

    // FIXME: This basically ignores Redux!
    const send = (newMessage, sendFnc) => {
      return newMessage != null
        ? this.props
            .dispatch(sendFnc(newMessage))

            .catch((error) => {
              console.log(error)
              const details =
                error.data && error.data.data && error.data.data.details
                  ? error.data.data.details
                  : [];
              const retryError = details.find((object) => object.code === 1130);
              const unknownError = details.find(
                (object) => object.code === 1131
              );

              if (retryError) {
                NotificationManager.error(
                  i18next.t("retryable_message_error"),
                  i18next.t("server_error"),
                  0
                );
              } else if (unknownError) {
                NotificationManager.error(
                  i18next.t("unknown_message_error"),
                  i18next.t("server_error"),
                  0
                );
              } else {
                const errorString = details.reduce(
                  (acc, val) => (acc += `${val.message + "\n\n"}`),
                  ""
                );
                NotificationManager.error(
                  errorString,
                  i18next.t("server_error"),
                  0
                );
              }
              return Promise.reject([{ message: newMessage }]);
            })
            .then(({ data }) =>
              handleAttachments(data.entity.language, data.entity.id)
                .then(() => data.entity)
                .catch((error) =>
                  Promise.reject([{ message: data.entity, error }])
                )
            )
            .then((message) => {
              message.messageStatus = messageStatus;
              return message;
            })
            .then((message) => {
              message["createTwoStep"] = messageStatus === "PUBLISHED";
              if (
                this.state.selectedDistributionLists &&
                this.state.selectedDistributionLists.length > 0
              ) {
                message["distributionLists"] =
                  this.state.selectedDistributionLists.map(
                    (list) => list.value
                  );
              }
              return [
                {
                  updateStatus: () =>
                    this.props
                      .dispatch(updateNewsMessage(message, true))
                      .catch((err) => {
                        console.log(err);
                        if (
                          err &&
                          err.data &&
                          err.data.details &&
                          err.data.details.length > 0
                        ) {
                          console.log(err.data.details);
                          const emailServiceDownError = err.data.details.find(
                            (detail) => detail.code === 1150
                          );

                          if (emailServiceDownError) {
                            NotificationManager.error(
                              <ErrorText err500>
                                {this.props.t(
                                  "edist_emaildist_service_unavailable"
                                )}
                              </ErrorText>,
                              "500 Server Error",
                              0
                            );
                          } else {
                            const errorString =
                              err.data.details != null &&
                              err.data.details.reduce(
                                (acc, val) =>
                                  (acc += `${val.message + "\n\n"}`),
                                ""
                              );
                            NotificationManager.error(
                              <ErrorText>{errorString}</ErrorText>,
                              i18next.t("server_error"),
                              0
                            );
                          }
                        }
                        throw err;
                      }),
                  message,
                },
              ];
            })
        : Promise.resolve([
            {
              updateStatus: () => undefined,
              message: null,
            },
          ]);
    };

    return new Promise((resolve, reject) => {
      setPublishedTime();

      switch (this.props.newsMessageAction) {
        case NewsMessageAction.EDIT:
          var newsMessage = createMessageObject(
            message,
            this.props.newsMessage.language,
            false
          );
          newsMessage.messageStatus = messageStatus;

          return handleAttachments(newsMessage.language, newsMessage.id)
            .then(() =>
              this.props.dispatch(updateNewsMessage(newsMessage, false))
            )
            .then(() => resolve())
            .catch((err) => {
              console.log(err);
              return reject(err);
            });
        case NewsMessageAction.CORRECTION:
          var newsMessage = createMessageObject(
            message,
            this.props.newsMessage.language,
            false
          );

          return send(newsMessage, updateNewsMessage)
            .then((sendObject) => sendObject[0].updateStatus())
            .then(resolve)
            .catch((sendObj) => reject(sendObj.error));

        case NewsMessageAction.NEW:
          const newsMessageNo = createMessageObject(
            message,
            "no");
          const newsMessageEn = createMessageObject(
            message,
            "en");

          return send(newsMessageNo, createNewsMessage)
            .then((sendObjNo) =>
              send(newsMessageEn, createNewsMessage).then(
                (sendObjEn) => sendObjNo.concat(sendObjEn),
                (sendObjEn) => sendObjNo.concat(sendObjEn)
              )
            )
            .then((sendObjects) => {
              Promise.all(sendObjects.map((obj) => {
                obj.updateStatus()
              }))
                  .catch(
                () => Promise.reject(sendObjects)
              )}
            )
            .then(resolve)
            .catch((sendObjects) => {
              let objects = sendObjects
              if (sendObjects[0] === undefined) {
                objects = new Array(sendObjects);
              }

              Promise.all(
                  objects
                  .filter(
                    (obj) => obj.message != null && obj.message.id != null
                  )
                  .map((obj) =>
                    this.props.dispatch(removeNewsMessage(obj.message.id))
                  )
              ).finally(reject) }
            );
      }
    });
  };

  closeHelpModal = () => {
    this.setState({
      showHelpModal: false,
      helpModalText: undefined,
      helpModalHeaderText: undefined,
    });
  };

  showHelp = (helpText, headerText) => {
    this.setState({
      showHelpModal: true,
      helpModalText: helpText,
      helpModalHeaderText: headerText,
    });
  };

  render() {
    const { t, base, config } = this.props;
    const onCancel = () => {
      if (this.props.newsMessageAction === NewsMessageAction.CORRECTION) {
        this.props.dispatch(removeNewsMessage(this.props.newsMessage.id))
        .then(() => this.props.dispatch(resetMessageData()));
      }
      this.props.history.push(`${this.props.messageListURL}`);
      this.props.dispatch(destroy("newMessageFormTwo"));
      this.props.dispatch(destroy("newNewsMessage"))
    };

    const onSelectCategory = (value) => {
      if (value != null) {
        if (value.categoryConfig.obdnOn === true) {
          this.props.dispatch(
            change("newNewsMessage", "distributionRequest", true)
          );
        }
        if (value.categoryConfig.obdnOn === false) {
          this.props.dispatch(
            change("newNewsMessage", "distributionRequest", false)
          );
        }

        this.setState({
          obdnConfig: value.categoryConfig,
        });
      }
    };

    const createTemplateList = () => {
      var tempCatList = [];

      this.props.categoryList.forEach((category, catIndex) => {
        if (category.templates != null) {
          category.templates.forEach((template, tempIndex) => {
            const { id, templates, ...rest } = category;
            tempCatList.push({
              id,
              value: "" + catIndex + tempIndex,
              label: template["name" + this.userLanguage()],
              titleNo: template["titleNo"],
              titleEn: template["titleEn"],
              textEn: template["textEn"],
              textNo: template["textNo"],
              categoryConfig: category.categoryConfig,
            });
          });
        }
      });

      return tempCatList;
    };

    const onSelectTemplate = (value) => {
      if (value != null) {
        this.props.dispatch(
          change("newNewsMessage", "title_no", value.titleNo)
        );
        this.props.dispatch(
          change(
            "newNewsMessage",
            "distributionRequest",
            value.categoryConfig.obdnOn
          )
        );
        this.props.dispatch(
          change("newNewsMessage", "title_en", value.titleEn)
        );
        this.props.dispatch(
          change("newNewsMessage", "message_no", value.textNo)
        );
        this.props.dispatch(
          change("newNewsMessage", "message_en", value.textEn)
        );
        this.props.dispatch(
          change("newNewsMessage", "category", {
            value: value.id,
          })
        );
        this.setState({
          obdnConfig: value.categoryConfig,
          selectedTemplateCategory: value.id,
        });
      }
    };

    const shouldShowNbInputFields = () => {
      if (!(config && config.languages)) {
        return this.props.newsMessageAction !== NewsMessageAction.NEW
          ? ["nb", "no"].includes(this.props.newsMessage.language.toLowerCase())
          : true;
      } else {
        if (config.languages.includes("nb")) {
          return this.props.newsMessageAction !== NewsMessageAction.NEW
            ? ["nb", "no"].includes(
                this.props.newsMessage.language.toLowerCase()
              )
            : true;
        } else {
          return false;
        }
      }
    };

    const shouldShowEnInputFields = () => {
      if (!(config && config.languages)) {
        return this.props.newsMessageAction !== NewsMessageAction.NEW
          ? ["en"].includes(this.props.newsMessage.language.toLowerCase())
          : true;
      } else {
        if (config.languages.includes("en")) {
          return this.props.newsMessageAction !== NewsMessageAction.NEW
            ? ["en"].includes(this.props.newsMessage.language.toLowerCase())
            : true;
        } else {
          return false;
        }
      }
    };

    const getDistributionLists = () => {
      const { distributionList } = this.props;

      return distributionList &&
        distributionList.length &&
        distributionList.length > 0
        ? distributionList
            .filter(
              (list) =>
                this.state.selectedDistributionLists.find(
                  (selectedList) => selectedList.value === list.id
                ) == null
            )
            .map((list) => {
              return {
                label: list.name,
                value: list.id,
              };
            })
        : [];
    };

    const listSelected = (selected) => {
      let tempSelectedList = this.state.selectedDistributionLists;
      tempSelectedList.push(selected);
      this.setState({ selectedDistributionLists: tempSelectedList });
    };

    const listDeselected = (deSelectedIndex) => {
      let tempSelectedList = this.state.selectedDistributionLists;
      tempSelectedList.splice(deSelectedIndex, 1);
      this.setState({ selectedDistributionLists: tempSelectedList });
    };

    return (
      <FlexContainer>
        <Switch>
          <Route
            path={`${base}/${FORMTWO}`}
            render={(props) => (
              <FormTwo
                superEditPermission={this.props.superEditPermission}
                onBack={() => this.props.history.push(`${base}/${FORMONE}`)}
                onCancel={onCancel}
                onSaveDraft={() =>
                  this.sendToServer(this.props.newNewsMessage, "DRAFT")
                    .then(() => this.props.dispatch(fetchDraftMessageList()))
                    .then(() =>
                      this.props.history.push(`${base}/${NEWSRECIEPT}/draft`)
                    )
                }
                onSubmit={() =>
                  this.sendToServer(
                    this.props.newNewsMessage,
                    "PUBLISHED").then(() => {
                    return this.props.history.push(`${base}/${NEWSRECIEPT}`);
                  })
                }
                t={this.props.t}
                categoryLabel={
                  this.props.newNewsMessage != null &&
                  this.props.categoryList.find(
                    (category) =>
                      category.id ===
                      this.getCurrentCategoryId(this.props.newNewsMessage)
                  )["name" + this.userLanguage()]
                }
                languages={
                  this.props.newsMessageAction !== NewsMessageAction.NEW &&
                  this.props.newsMessage != null
                    ? [this.props.newsMessage.language]
                    : this.props.languages
                }
                initialValues={{
                  ...this.createInitalValuesFormTwo(this.props.newsMessage),
                  timeZone: {
                    label: this.state.userTimeZone,
                    value: this.state.userTimeZone,
                  },
                }}
                holidays={this.props.messageParams.holidays}
                fetchedMessage={this.props.newsMessage}
                timeZoneNames={this.state.timeZoneNames}
                delayedPublicationMaxDaysOnlyBusinessDays={
                  this.props.messageParams
                    .delayedPublicationMaxDaysOnlyBusinessDays
                }
                delayedPublicationMaxDays={
                  this.props.messageParams.delayedPublicationMaxDays
                }
                selectedDistributionLists={this.state.selectedDistributionLists}
              />
            )}
          />
          <Route
            path={`${base}/${NEWSRECIEPT}/:type?`}
            render={(props) => {
              const { type } = props.match.params;
              return (
                <NewsReceipt
                  superEditPermission={this.props.superEditPermission}
                  t={t}
                  cleanUpOnUnmount={() => {
                    this.props.dispatch(destroy("newNewsMessage"));
                    this.props.dispatch(destroy("newMessageFormTwo"));
                    this.props.dispatch(resetMessageData());
                  }}
                  newsMessageAction={this.props.newsMessageAction}
                  isDraft={type && type.toLowerCase() === "draft"}
                  onMessageListClick={() => {
                    this.setState({overlayLoader: true})
                    this.props.dispatch(fetchMessageList()).then(() => {
                      this.props.history.push(`${this.props.messageListURL}`);
                    })
                  }}
                  loading={this.state.overlayLoader}
                  futureMessage={
                    this.props.publishTimeType === NewsPublishTimeType.FUTURE
                  }
                />
              );
            }}
          />

          <Route
            path={`${base}`}
            render={(props) => {
              const initalValues = this.createInitalValuesFormOne(
                this.props.newsMessage
              );

              const renderForm =
                ([
                  NewsMessageAction.EDIT,
                  NewsMessageAction.CORRECTION,
                ].includes(this.props.newsMessageAction) &&
                  this.props.attachmentListIsFetched &&
                  this.props.newsMessage != null &&
                  initalValues !== undefined) ||
                this.props.newsMessageAction === NewsMessageAction.NEW;

              return renderForm ? (
                <FirstForm
                  newsMessageAction={this.props.newsMessageAction}
                  superEditPermission={this.props.superEditPermission}
                  userLoggedIn={this.props.userLoggedIn}
                  obdnConfig={this.state.obdnConfig}
                  messageTemplates={this.state.message}
                  enableReinitialize={false}
                  onSubmit={() => this.props.history.push(`${base}/${FORMTWO}`)}
                  onCancel={onCancel}
                  companyOptionList={
                    this.props.companyList != null
                      ? this.props.companyList.map((elm) => {
                          return {
                            label: elm.longName,
                            helpText: elm.symbol,
                            value: elm.cid,
                          };
                        })
                      : []
                  }
                  onSelectTemplate={onSelectTemplate}
                  templateList={createTemplateList()}
                  categoriesOptionList={
                    this.props.categoryList != null
                      ? this.props.categoryList.map((elm) => {
                          return {
                            label: elm["name" + this.userLanguage()],
                            helpText: elm["description" + this.userLanguage()],
                            value: elm.id,
                            categoryConfig: elm.categoryConfig,
                            templates: elm.templates,
                          };
                        })
                      : []
                  }
                  securitiesOptionList={
                    this.props.securities != null
                      ? this.props.securities.map((elm) => {
                          return { label: elm.symbol, value: elm.sid };
                        })
                      : []
                  }
                  getAttachment={this.props.getAttachment}
                  onSelectIssuer={(value) => {
                    this.props.dispatch(
                      change("newNewsMessage", "instrument", null)
                    );
                    this.props.dispatch(
                      change("newNewsMessage", "category", null)
                    );
                    if (value != null) {
                      this.props
                        .dispatch(fetchSecurities(value.value))
                        .then(() =>
                          this.props.dispatch(fetchCategories(value.value))
                        )
                        .then(() =>
                          this.props.dispatch(fetchCompany(value.value))
                        )
                        .then(() => createTemplateList())
                        .catch((err) => {});
                    } else {
                      this.props.dispatch(clearSecurities());
                    }
                  }}
                  onSelectCategory={onSelectCategory}
                  showNbInputFields={shouldShowNbInputFields()}
                  showEnInputFields={shouldShowEnInputFields()}
                  t={this.props.t}
                  showHelp={this.showHelp}
                  maxFileSize={this.props.messageParams.attachMaxSize}
                  maxNumFiles={this.props.messageParams.maxNumAttach}
                  fileType={"application/pdf"}
                  distributionLists={getDistributionLists()}
                  selectList={listSelected}
                  deselectList={listDeselected}
                  selectedDistributionLists={
                    this.state.selectedDistributionLists
                  }
                  initialValues={{
                    ...initalValues,
                  }}
                />
              ) : (
                ""
              );
            }}
          />

          <Redirect to={`${base}/${FORMONE}`} />
        </Switch>
        <HelpModal
          isOpen={this.state.showHelpModal}
          onClose={this.closeHelpModal}
          header={this.state.helpModalHeaderText}
          width={100}
        >
          {this.state.helpModalText}
        </HelpModal>
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { form, config, emailDistributionReducer, messageReducer } = state;

  return {
    newNewsMessage:
      form.newNewsMessage != null ? form.newNewsMessage.values : undefined,

    publishTimeType:
      form.newMessageFormTwo != null && form.newMessageFormTwo.values != null
        ? form.newMessageFormTwo.values.publishTime
        : undefined,
    publishDate:
      form.newMessageFormTwo != null && form.newMessageFormTwo.values != null
        ? form.newMessageFormTwo.values.pDate
        : undefined,
    publishTime:
      form.newMessageFormTwo != null && form.newMessageFormTwo.values != null
        ? form.newMessageFormTwo.values.pTime
        : undefined,
    publishTimeZone:
      form.newMessageFormTwo != null && form.newMessageFormTwo.values != null
        ? form.newMessageFormTwo.values.timeZone
        : undefined,
    config: config.config,
    distributionList: emailDistributionReducer.distributionList.isFetched
      ? emailDistributionReducer.distributionList.list
      : [],
    message:
      messageReducer.message && messageReducer.message.data
        ? messageReducer.message.data
        : {},
  };
}

export default connect(mapStateToProps)(EditContainer);
