import React from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {Field, reduxForm, SubmissionError} from "redux-form";

import RadioButton from "../components/RadioButton";
import DayPicker from "../components/DayPicker";
import TimePicker from "../components/TimePicker";
import {FlexContainer} from "../components/FlexContainer";
import Table, {TD, TR} from "../components/Table";
import Button from "../components/Button";
import Label from "../components/Label";
import {PageHeading} from "../components/PageHeading";
import {FileUploadObjectType} from "../components/FileUploader";
import {NewsPublishTimeType} from "./EditContainer";
import {ErrorText} from "../components/ErrorText";
import moment from "moment-timezone";
import Loader from "../components/Loader";
import SearchableSelectNew from "../components/SearchableSelectNew";
import {ConfirmModal} from "../components/ConfirmModal";

const PreviousPage = styled.div``;

const Icon = styled.img`
  cursor: pointer;
  padding: 0px 5px 0 5px;
  margin-right: 8px;
  box-sizing: border-box;
`;

const TextButton = styled.span`
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const HideOptions = styled.div`
  ${(props) => (props.currentTime ? "" : "display: none;")};
`;

const addWeekdays = (date, days, holidays) => {
  const isholiday = (holidayDate) => {
    const mHoliday = moment.tz(holidayDate, "Europe/Oslo");
    return (
      mHoliday.date() === date.date() &&
      mHoliday.month() === date.month() &&
      mHoliday.year() === date.year()
    );
  };

  while (days > 0) {
    // decrease "days" only if it's a weekday.
    if (holidays == null) break;
    else if (!holidays.find(isholiday)) days -= 1;
    if (days > 0) date.add(1, "days");
  }
  return date;
};

const RadioButtonGroup = (props) => {
  //const input = {onChange: props.onCurrentTime}

  return (
    <FlexContainer column>
      <FlexContainer padding={[18, 0, 0, 0]}>
        <RadioButton
          checked={props.publishNow}
          margin={[0, 0, 16, 0]}
          radioValue={NewsPublishTimeType.NOW}
          label={props.t("instant_publish_message")}
          {...props}
        />
        <RadioButton
          checked={!props.publishNow}
          margin={[0, 0, 16, 0]}
          radioValue={NewsPublishTimeType.FUTURE}
          label={props.t(
            props.localDateTime
              ? "publish_message_at_given_local_time"
              : "publish_message_at_given_time"
          )}
          {...props}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export const TimeBox = (props) => (
  <FlexContainer column noMargin maxWidth={178} margin={[0, 0, 0, 16]}>
    <Label htmlFor={props.input.name}>{props.label}</Label>
    <TimePicker {...props} />
    {props.meta.error ? <ErrorText>{props.meta.error}</ErrorText> : undefined}
  </FlexContainer>
);

const DateBox = (props) => {
  let endDay;
  if (props.delayedPublicationMaxDaysOnlyBusinessDays) {
    endDay = addWeekdays(
      moment.tz("Europe/Oslo"),
      props.delayedPublicationMaxDays,
      props.holidays
    ).startOf("day");
  } else {
    endDay = moment().add(props.delayedPublicationMaxDays, "days");
  }
  const startDay = props.localDateTime ? moment().startOf("day") : moment.tz("Europe/Oslo").startOf("day");

  return (
    <FlexContainer column maxWidth={275} marginRight={0} marginLeft={35}>
      <Label htmlFor={props.input.name}>{props.label}</Label>
      <DayPicker
        {...props}
        border={true}
        disabledDays={(day) => {
          const mDay = moment(day).startOf("day");

          if (mDay.isAfter(endDay)) return true;

          const date = new Date(day);
          const offset = date.getTimezoneOffset();
          const dateWithoutOffset = new Date(date.getTime() - (offset * 60000));

          return props.localDateTime ? mDay.isBefore(startDay) : startDay.toDate() > dateWithoutOffset;
        }}
      />
      {props.meta.error ? <ErrorText>{props.meta.error}</ErrorText> : undefined}
    </FlexContainer>
  );
};

const SearchableSelectBox = (props) => (
  <FlexContainer column noMargin maxWidth={400} margin={[0, 0, 0, 16]}>
    <Label htmlFor={props.input.name}>{props.label}</Label>
    <SearchableSelectNew options={props.options} t={props.t} {...props} />
  </FlexContainer>
);

const createTable = (
  objValues,
  language,
  categoryLabel,
  t,
  left,
  superEditPermission,
  selectedDistributionLists,
  publicProps
) => {
  const issuer = objValues.issuer != null ? objValues.issuer.label : "";
  const instrument =
    objValues.instrument != null ? objValues.instrument.label : "";
  const OBDN = objValues.distributionRequest ? t("yes") : t("no");

  // `${objValues["text_" + language] || ""}`;

  const tableHeaderLabels = [t(language + "_message")];
  const tableHeaderProps = [{ colSpan: 2 }];

  const getDistributionLists = () => {
    if (selectedDistributionLists && selectedDistributionLists.length > 0) {
      return selectedDistributionLists
        .map((selectedList) => {
          return selectedList.label;
        })
        .join(", ");
    }
  };

  const tableRows = [
    <TR key="1">
      <TD bold>{t("issuer")}:</TD>
      <TD>{issuer}</TD>
    </TR>,
    <TR key="2">
      <TD bold>{t("instrument")}:</TD>
      <TD>{instrument}</TD>
    </TR>,
    <TR key="3">
      <TD bold>{t("category")}:</TD>
      <TD>{categoryLabel}</TD>
    </TR>,
    <TR key="4">
      <TD bold>{t("messageTitle")}:</TD>
      <TD pre_wrap>{objValues["title_" + language]}</TD>
    </TR>,
    <TR key="5">
      <TD bold alignTop>
        {t("message")}:
      </TD>
      <TD breakWords pre_wrap width={829}>
        {objValues["message_" + language]}
      </TD>
    </TR>,
    <TR key="6">
      <TD bold>{t("attachments")}:</TD>
      <TD>
        {objValues["attachments_" + language] != null
          ? objValues["attachments_" + language]
              .filter(
                (fileObj) =>
                  fileObj.type !== FileUploadObjectType.DELETED_SERVER_FILE
              )
              .map((fileObj) => fileObj.fileName)
              .join(", ")
          : ""}
      </TD>
    </TR>,
    !superEditPermission ? (
      <TR key="7">
        <TD bold>{t("publishOBDN")}:</TD>
        <TD>{OBDN}</TD>
      </TR>
    ) : undefined,
    publicProps &&
    publicProps["global.module.emaildist"] &&
    publicProps["global.module.emaildist"] === "true" ? (
      <TR key="8">
        <TD bold>{t("email_distribution_to")}</TD>
        <TD>{getDistributionLists()}</TD>
      </TR>
    ) : undefined,
  ];

  return (
    <Table
      key={"confirmTable_" + language}
      margin={left ? [0, 20, 20, 0] : [0, 0, 0, 0]}
      tableRows={tableRows}
      tableHeaderLabels={tableHeaderLabels}
      tableHeaderProps={tableHeaderProps}
    />
  );
};

class FormTwo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      publishNow: true,
      loading: false,
    };
  }

  componentWillMount() {
    if (this.props.initialValues.publishTime === NewsPublishTimeType.FUTURE) {
      this.setState({ publishNow: false });
    }
  }

  render() {
    const props = this.props;

    const { t } = props;
    var tables = [];
    var left = true;
    props.languages.forEach((language) => {
      if (
        props.objValues["message_" + language] &&
        props.objValues["title_" + language]
      ) {
        tables.push(
          createTable(
            props.objValues,
            language,
            props.categoryLabel,
            props.t,
            left,
            props.superEditPermission,
            props.selectedDistributionLists,
            props.publicProps
          )
        );
        left = !left;
      }
    });

    const validate = (values, props) => {
      const errors = {};

      const timeZoneValue = values.timeZone.value;

      if (!this.state.publishNow && values.pDate && values.pTime) {
        const publishTime = moment.tz(
          values.pDate + " " + values.pTime,
          "DD-MM-YYYY HH:mm",
          props.localDateTime ? timeZoneValue : "Europe/Oslo"
        );
        let endDay;
        if (props.delayedPublicationMaxDaysOnlyBusinessDays) {
          endDay = addWeekdays(
            moment.tz("Europe/Oslo"),
            props.delayedPublicationMaxDays,
            props.holidays
          ).endOf("day");
        } else {
          endDay = moment().add(props.delayedPublicationMaxDays, "days");
        }
        if (props.localDateTime) {
          if (publishTime.isBefore(moment().tz(timeZoneValue))) {
            errors.pDate = props.t("publish_time_before_now_not_allowed");
          }
          if (publishTime.isAfter(endDay)) {
            errors.pDate = props.t(
              "more_than_two_days_into_future_is_not_allowed"
            );
          }
        } else {
          if (publishTime.isBefore(moment().tz("Europe/Oslo"))) {
            errors.pDate = props.t("publish_time_before_now_not_allowed");
          }
          if (publishTime.isAfter(endDay)) {
            errors.pDate = props.t(
              "more_than_two_days_into_future_is_not_allowed"
            );
          }
        }
      }

      if (!values.pDate && !this.state.publishNow) {
        errors.pDate = props.t("required_field");
      }
      if (!values.pTime && !this.state.publishNow) {
        errors.pTime = props.t("required_field");
      }

      return Object.keys(errors).length > 0 ? errors : undefined;
    };

    const onSubmit = (values) => {
      return new Promise((resolve, reject) => {
        const errors = validate(values, this.props);

        if (errors) {
          this.setState(
            { loading: false },
            reject(new SubmissionError(errors))
          );
        } else {
          this.props
            .onSubmit()
            .then(resolve)
            .catch(() =>
              this.setState({ loading: false }, reject(new SubmissionError({})))
            );
        }
      });
    };

    const saveDraft = (values) => {
      return new Promise((resolve, reject) => {
        const errors = validate(values, this.props);

        if (errors) {
          this.setState(
            { loading: false },
            reject(new SubmissionError(errors))
          );
        } else {
          this.props
            .onSaveDraft()
            .then(resolve)
            .catch(() => this.setState({ loading: false }, reject));
        }
      });
    };

    return (
      <FlexContainer column>
        <PageHeading margin={[0, 0, 16, 0]}>
          {t("new_news_message_provisioning")}
        </PageHeading>
        {!this.state.loading ? (
          <form autoComplete="off">
            <FlexContainer spaceBetween column>
              {tables}
            </FlexContainer>

            {this.props.fetchedMessage &&
            this.props.fetchedMessage.messageStatus ===
              "PUBLISHED" ? undefined : (
              <FlexContainer
                greyBackground
                margin={[40, 0, 0, 0]}
                padding={[16, 16, 16, 16]}
              >
                <PageHeading>{t("publish_time")}</PageHeading>

                <Field
                  name="publishTime"
                  onChange={(e, radioValue) =>
                    radioValue === NewsPublishTimeType.NOW
                      ? this.setState({ publishNow: true })
                      : this.setState({ publishNow: false })
                  }
                  component={RadioButtonGroup}
                  publishNow={this.state.publishNow}
                  t={t}
                  localDateTime={this.props.localDateTime}
                />

                <HideOptions currentTime={!this.state.publishNow}>
                  <FlexContainer row>
                    <Field
                      component={DateBox}
                      name={"pDate"}
                      label={t("date")}
                      holidays={this.props.holidays}
                      delayedPublicationMaxDaysOnlyBusinessDays={
                        this.props.delayedPublicationMaxDaysOnlyBusinessDays
                      }
                      delayedPublicationMaxDays={
                        this.props.delayedPublicationMaxDays
                      }
                      localDateTime={props.localDateTime}
                    />

                    <Field component={TimeBox} name="pTime" label={t("time")} />

                    {this.props.localDateTime ? (
                      <Field
                        component={SearchableSelectBox}
                        name="timeZone"
                        options={this.props.timeZoneNames}
                        t={t}
                        label={t("timeZone")}
                      />
                    ) : undefined}
                  </FlexContainer>
                </HideOptions>
              </FlexContainer>
            )}
            {props.objValues.test &&
            (props.objValues.test === "true" || props.objValues.test === true) ? (
              <FlexContainer margin={[15, 0, 0, 0]} center vAlignCenter>
                <ErrorText fontSize={"26px"}>
                  {t("this_is_a_test_message_warning")}
                </ErrorText>
              </FlexContainer>
            ) : undefined}
            <FlexContainer
              spaceBetween
              vAlignCenter
              row
              margin={[39, 0, 24, 0]}
            >
              <FlexContainer width="auto">
                <PreviousPage onClick={props.onBack}>
                  <Icon src="/icons/back-arrow-24-px.svg" />
                  <TextButton>{t("back")}</TextButton>
                </PreviousPage>
              </FlexContainer>
              <FlexContainer width="auto" row vAlignCenter>
                {this.props.fetchedMessage &&
                this.props.fetchedMessage.messageStatus ===
                  "PUBLISHED" ? undefined : (
                  <Button.Standard
                    inverted
                    margin={[0, 16, 0, 0]}
                    inactive={this.state.loading}
                    onClick={() => {
                      if (!this.state.loading)
                        this.setState(
                          { loading: true },
                          props.handleSubmit(saveDraft)
                        );
                    }}
                  >
                    {" "}
                    {t("save_as_draft")}
                  </Button.Standard>
                )}
                <Button.Standard
                  margin={[0, 16, 0, 0]}
                  inactive={this.state.loading}
                  onClick={() => {
                    if (!this.state.loading) {
                      if (this.state.publishNow) {
                        this.setState({showConfirmModal: true});
                      } else {
                        this.setState(
                            {loading: true},
                            props.handleSubmit(onSubmit)
                        );
                    }
                  }}}
                >
                  {t(
                    this.props.fetchedMessage &&
                      this.props.fetchedMessage.messageStatus === "PUBLISHED"
                      ? "save"
                      : "publish"
                  )}
                </Button.Standard>
                <Button.Text onClick={props.onCancel}>
                  {" "}
                  {t("cancel")}
                </Button.Text>
                {this.state.showConfirmModal &&
                    <ConfirmModal
                        center
                        header={t("confirm_instant_publishing_title")}
                        isOpen={this.state.showConfirmModal}
                        onClose={() => this.setState({ showConfirmModal: false })}
                        confirmText={t("publish")}
                        cancelText={t("cancel")}
                        onConfirm={() => {
                          this.setState(
                              {loading: true},
                              props.handleSubmit(onSubmit)
                          );
                        }}
                    >
                      <p>{t("confirm_instant_publishing")}</p>
                    </ConfirmModal>
                }
              </FlexContainer>
            </FlexContainer>
          </form>
        ) : (
          <Loader />
        )}
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { form, config, systemAdministratorReducer } = state;
  return {
    objValues:
      form.newNewsMessage != null ? form.newNewsMessage.values || {} : {},
    localDateTime: config.config.localDateTime,
    publicProps: systemAdministratorReducer.publicProps.object || {},
  };
}

export default reduxForm({
  form: "newMessageFormTwo",
  destroyOnUnmount: false,
})(connect(mapStateToProps)(FormTwo));
