export const validatePassword = (values, props, requiredFieldsList) => {
  const errors = {};
  Object.keys(values).forEach((key) =>
    values[key] != null && values[key].length === 0
      ? (values[key] = null)
      : undefined
  );

  const requiredFieldsCheck = () => {
    if (requiredFieldsList) {
      requiredFieldsList.forEach((field) => {
        if (values[field] == null || values[field].length < 1) {
          errors[field] = props.t("required_field");
        }
      });
    }
  };

  const passwordRequirement = () => {
    //diffrent password
    if (values.newPassword !== values.repeatPassword) {
      errors.repeatPassword = props.t("passwords_does_not_match");
    }

    //only numeric
    if (
      values.newPassword != null &&
      /^\d+$/i.test(values.newPassword) &&
      values.newPassword.length > 0
    ) {
      errors.newPassword = props.t("only_numeric");
    }

    if (values.newPassword != null && values.newPassword.length < 10) {
      errors.newPassword = props.t("min_signs");
    }
  };

  const valueFormatCheck = () => {
    if (
      values.twoFactorPhone &&
      !/^\+[0-9]+|[0-9]+/i.test(values.twoFactorPhone) &&
      values.twoFactorPhone.length > 0
    ) {
      errors.twoFactorPhone = props.t("invalid_phonenumber");
    }
  };

  requiredFieldsCheck();
  valueFormatCheck();
  passwordRequirement();

  console.log(errors);

  return Object.keys(errors).length > 0 ? errors : undefined;
};
