import React from "react";
import SearchableSelect from "../components/SearchableSelect";
import { Field, reduxForm } from "redux-form";
import CheckBox from "../components/CheckBox";
import { FlexContainer } from "../components/FlexContainer";
import Button from "../components/Button";
import Label from "../components/Label";
import { PageHeading } from "../components/PageHeading";
import { Modal } from "../components/Modal";
import Input from "../components/Input";
import { connect } from "react-redux";
import { fetchCompanyPermission } from "../actions/CommonActions";
import { MultiSelect } from "./CompanyDetails";
import { ErrorText } from "../components/ErrorText";
import SearchableSelectNew from "../components/SearchableSelectNew";

export const COMPANY_SELECT = "companySelect";

const IsActiveBox = props => (
  <FlexContainer row vAlignCenter margin={[0, 76, 0, 0]}>
    <CheckBox checked {...props} />
  </FlexContainer>
);

export const SelectBox = props => {
  return <SearchableSelect {...props} />;
};

export const InputFormWLabel = props => {
  const { error } = props.meta;

  return (
    <FlexContainer column margin={[0, 8, 0, 0]}>
      <Label
        htmlFor={props.name}
        maxWidth={185}
        margin={[8, 8, 3, 0]}
        {...props}
      >
        {props.label}:
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <Input
        placeholder={props.placeholder}
        greyPlaceholder
        {...props.input}
        {...props}
      />
    </FlexContainer>
  );
};

export const SearchDropDownWlabel = props => {
  const { initalCompanyValue } = props;

  return (
    <FlexContainer column margin={[0, 8, 0, 0]}>
      <Label
        bold
        htmlFor={props.name}
        maxWidth={185}
        margin={[8, 8, 3, 0]}
        {...props}
      >
        {props.label}:
      </Label>
      <SearchableSelectNew
        {...props}
        clearable={true}
        searchable={true}
        onSelect={props.onCompanySelect}
        selectIfOnlyOneOption
      />
    </FlexContainer>
  );
};

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(fetchCompanyPermission());
  }

  render() {
    const { t } = this.props;

    return (
      <FlexContainer key={"newCompanyForm"}>
        <FlexContainer column>
          <form>
            <PageHeading margin={[0, 0, 16, 0]}>
              {t("company_information")}
            </PageHeading>
            <FlexContainer row vAlignEnd>
              <Field
                component={SearchDropDownWlabel}
                name={COMPANY_SELECT}
                label={t("company")}
                initalCompanyValue={this.props.initalCompanyValue}
                options={this.props.companyList}
                onCompanySelect={this.props.onCompanySelect}
                placeholder={t("select")}
                t={t}
              />
              {this.props.globalCompanyPermissions.some(
                value => value === "ci.create"
              ) ? (
                <Button.Standard
                  onClick={() => this.props.onOpenNewCompanyModal()}
                >
                  {" "}
                  {t("new")}{" "}
                </Button.Standard>
              ) : (
                ""
              )}
            </FlexContainer>
            <Modal
              key={"NewCompanyModal"}
              center
              header={t("add_company")}
              isOpen={this.props.showNewCompanyModal}
              onClose={this.props.onCloseNewCompanyModal}
            >
              <FlexContainer>
                <MultiSelect
                  isVisible={() => true}
                  isRequired={() => true}
                  t={this.props.t}
                  placeholder={t("select")}
                  options={this.props.companyRoleTypes}
                  name="roles"
                  label={t("company_type") + "*"}
                />

                <MultiSelect
                  isVisible={() => true}
                  isRequired={() => true}
                  isEditable={() => true}
                  t={this.props.t}
                  placeholder={t("select")}
                  options={this.props.issuerStatusTypes || []}
                  name="issuerStatus"
                  label={t("issuer_status")}
                />

                <Field
                  component={InputFormWLabel}
                  border
                  name="orgno"
                  label={t("bus_number") + "*"}
                />
                <Field
                  component={InputFormWLabel}
                  border
                  name="longName"
                  label={t("long_name") + "*"}
                />
                <Field
                  component={InputFormWLabel}
                  border
                  name="shortName"
                  label={t("short_name") + "*"}
                />
                <Field
                  component={InputFormWLabel}
                  border
                  name="symbol"
                  label={t("symbol")}
                />
                <Field
                  component={InputFormWLabel}
                  border
                  name="leiCode"
                  label={t("lei_code")}
                />
              </FlexContainer>
              <FlexContainer column>
                <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
                  <Button.Standard
                    margin={[0, 16, 0, 0]}
                    onClick={this.props.handleSubmit(this.props.saveNewCompany)}
                  >
                    {t("save")}
                  </Button.Standard>
                  <Button.Text
                    onClick={() => this.props.onCloseNewCompanyModal()}
                  >
                    {t("cancel")}
                  </Button.Text>
                </FlexContainer>
                <FlexContainer row center margin={[20, 0, 0, 0]}>
                  <Field
                    name="validationError"
                    component={props => {
                      const { error } = props.meta;
                      return (
                        <div>
                          {error ? <ErrorText>{error}</ErrorText> : undefined}
                        </div>
                      );
                    }}
                  />
                </FlexContainer>
              </FlexContainer>
            </Modal>
          </form>
        </FlexContainer>
      </FlexContainer>
    );
  }
}

CompanyForm = reduxForm({
  form: "newCompanyForm"
})(CompanyForm);

function mapStateToProps(state) {
  const { permissionReducer, companyPermissionReducer } = state;

  return {
    permissions: permissionReducer.permissions || [],
    globalCompanyPermissions:
      companyPermissionReducer.globalCompanyPermissions || []
  };
}

export default connect(mapStateToProps)(CompanyForm);
