import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import RadioButton from "../components/RadioButton";
import Button from "../components/Button";
import { NotificationManager } from "react-notifications";
import { withTranslation } from "react-i18next";
import { fetchSystemProps } from "./SystemAdministratorActions";
import { Modal } from "../components/Modal";
import { updateSSOStatus } from "../actions/CommonActionsNoObsvc";

class SSOContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      onCloseModal: false
    };
  }

  render() {
    const props = this.props;
    return (
      <FlexContainer
        column
        height={"130px"}
        withTransition
        padding={[24, 0, 24, 64]}
        margin={[0, 0, 24, 0]}
      >
        <FlexContainer center row vAlignCenter fontSize="14px">
          <Button.Standard
            margin={[0, 24, 0, 0]}
            onClick={() => this.setState({ isOpen: true })}
          >
            {props.t("change")}
          </Button.Standard>
        </FlexContainer>
        <Modal
          onClose={() => this.setState({ isOpen: false })}
          isOpen={this.state.isOpen}
          header={this.props.t("system_sso_warning")}
          width={400}
        >
          <FlexContainer>
            <FlexContainer
              vAlignCenter
              center
              fontSize="16px"
              margin={[24, 0, 24, 24]}
            >
              <div>
                <Field
                  name="ssoEnabled"
                  component={RadioButton}
                  radioValue={"true"}
                  checked={props.isActive === "true"}
                  label={props.t("activate")}
                />

                <Field
                  name="ssoEnabled"
                  margin={[22, 0, 0, 0]}
                  component={RadioButton}
                  radioValue={"false"}
                  checked={props.isActive === "false"}
                  label={props.t("deactivate")}
                />
              </div>
            </FlexContainer>

            <FlexContainer margin={[24, 24, 0, 0]} flexEnd vAlignCenter row>
              <Button.Standard
                margin={[0, 0, 0, 0]}
                onClick={this.props.handleSubmit((values, dispatch) => {
                  return dispatch(updateSSOStatus(values.ssoEnabled))
                    .then(() => dispatch(fetchSystemProps()))
                    .then(() =>
                      NotificationManager.success(
                        this.props.isActive === "true"
                          ? this.props.t("system_sso_updated") +
                            this.props.t("system_sso_on")
                          : this.props.t("system_sso_updated") +
                            this.props.t("system_sso_off")
                      )
                    )
                    .then(() => this.setState({ isOpen: false }));
                })}
              >
                {props.t("save")}
              </Button.Standard>
              <Button.Text
                margin={[0, 0, 0, 16]}
                onClick={() => this.setState({ isOpen: false })}
              >
                {this.props.t("cancel")}
              </Button.Text>
            </FlexContainer>
          </FlexContainer>
        </Modal>
      </FlexContainer>
    );
  }
}

SSOContainer = reduxForm({
  form: "ssoContainerForm"
})(SSOContainer);

function mapStateToProps(state) {
  const { form } = state;

  return {
    selectedSSOStatus: form.ssoContainerForm
      ? form.ssoContainerForm.values.ssoEnabled
      : ""
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(SSOContainer)
);
