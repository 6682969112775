import {combineReducers} from "redux";
import {reducer as form} from "redux-form";
import loginReducer from "../login/LoginReducer";

import profileReducer from "../Profil/ProfileReducer";
import {
  CompanyListActions,
  CompanyPermission,
  LoginUserActions,
  PASSWORD_CHANGE_FAIL,
  PASSWORD_CHANGE_OK,
  PermissionsActions,
  UserOrganisationActions,
} from "../actions/CommonActions";
import adminReducer from "../admin/AdminReducer";
import messageReducer from "../messages/MessageReducer";
import companyInformationReducer from "../companyInformation/CompanyInformationReducer";
import auditlogReducer from "../auditlog/AuditlogReducer";
import infoboardReducer from "../infoboard/InfoboardReducer";
import insiderReducer from "../innsider/InsiderReducer";
import financialReducer from "../finanskalender/FinanceCalendarReducer";
import insiderToolReducer from "../insiderTool/InsiderToolReducer";
import newUserReducer from "../NewUser/NewUserReducer";
import notificationReducer from "../NotifyOB/NotificationReducer";
import systemAdministratorReducer from "../systemAdmin/SystemAdministratorReducer";
import {LOG_OUT} from "../login/LoginActions";
import emailDistributionReducer from "../emailDistribution/EmailDistributionReducer";
import insiderSyncDetailsReducer from "../systemAdmin/InsiderSyncApi/InsiderSyncDetialsReducer";
import issuanceReducer from "../emission/ShareIssuanceReducer";
import {FetchEnv, FetchSSOEnabledActions, Maintenance} from "../actions/CommonActionsNoObsvc";
import {CategoryActions} from "../messages/MessageActions";

const initalPasswordState = {
  passowordChangeSuccess: false,
  passwordChangeFailed: false,
};
const passwordChangeReducer = (
  state = {
    ...initalPasswordState,
  },
  action
) => {
  switch (action.type) {
    case PASSWORD_CHANGE_OK:
      return Object.assign({}, state, {
        passowordChangeSuccess: true,
      });
    case PASSWORD_CHANGE_FAIL:
      return Object.assign({}, state, {
        passwordChangeFailed: true,
      });
    default:
      return state;
  }
};

const initialCompanyListState = {
  list: [],
  map: undefined,
  companyListIsFetched: false,
};
const companyReducer = (
  state = {
    ...initialCompanyListState,
  },
  action
) => {
  switch (action.type) {
    case CompanyListActions.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        map:
          action.data.entity != null
            ? new Map(
                action.data.entity.map((company) => [company.cid, company])
              )
            : new Map(),
        companyListIsFetched: true,
      });
    default:
      return state;
  }
};

const initalCategoryState = {
  categories: [],
  categoryListIsFetched: false,
};
const categoryReducer = (
  state = {
    ...initalCategoryState,
  },
  action
) => {
  switch (action.type) {
    case CategoryActions.postSuccess:
      return Object.assign({}, state, {
        categories: action.data.data.entity,
        categoryListIsFetched: true,
      });
    case CategoryActions.request:
      return Object.assign({}, state, {
        categoryListIsFetched: false,
      });
    case CategoryActions.postFail:
      return Object.assign({}, state, {
        categoryListIsFetched: false,
      });

    default:
      return state;
  }
};

const initalPermissionState = {
  permissions: undefined,
  permissionsIsFetched: false,
};
const permissionReducer = (
  state = {
    ...initalPermissionState,
  },
  action
) => {
  switch (action.type) {
    case PermissionsActions.postSuccess:
      return Object.assign({}, state, {
        permissions: action.data.data.entity.permissions.globalPermissions,
        permissionsIsFetched: true,
      });
    default:
      return state;
  }
};

const initialVisibleModulesState = {
  visibleModules: undefined,
  visibleModulesIsFetched: false,
};
const visibleModulesReducer = (
  state = {
    ...initialVisibleModulesState,
  },
  action
) => {
  switch (action.type) {
    case PermissionsActions.postSuccess:
      return Object.assign({}, state, {
        visibleModules: action.data.data.entity.permissions.visibleModules,
        visibleModulesIsFetched: true,
      });
    default:
      return state;
  }
};

const initalCompanyPermissionState = {
  companyPermissions: undefined,
  globalCompanyPermissions: undefined,
  insiderPermissionsIsFetched: false,
};
const companyPermissionReducer = (
  state = {
    ...initalCompanyPermissionState,
  },
  action
) => {
  switch (action.type) {
    case CompanyPermission.postSuccess:
      return Object.assign({}, state, {
        companyPermissions:
          action.data.data.entity.permissions.companyPermissions,
        globalCompanyPermissions:
          action.data.data.entity.permissions.globalCompanyPermissions,
        insiderPermissionsIsFetched: true,
      });
    default:
      return state;
  }
};

const initialLoginUserState = {
  user: undefined,
  userOrganisation: undefined,
  isFetched: false,
  loginComplete: false,
  incompatibleUser: false,
};
export const loginUser = (
  state = {
    ...initialLoginUserState,
  },
  action
) => {
  switch (action.type) {
    case LoginUserActions.postSuccess:
      return Object.assign({}, state, {
        user: action.data.data.entity,
        isFetched: true,
      });
    case LoginUserActions.loginComplete:
      return Object.assign({}, state, {
        loginComplete: true,
      });
    case UserOrganisationActions.postSuccess:
      return Object.assign({}, state, {
        userOrganisation: action.data.data.entity,
      });
    case LOG_OUT:
      return Object.assign({}, state, {
        ...initialLoginUserState,
      });
    case LoginUserActions.incompatibleUser:
      return Object.assign({}, state, {
        incompatibleUser: true,
      });

    default:
      return state;
  }
};

export const config = (
  state = {
    config: {},
  },
  action
) => {
  if (action.type === FetchEnv.receive) {
    return Object.assign({}, state, {
      config: action.data.data.entity,
    });
  } else {
    return state;
  }
};

export const ssoEnabled = (
  state = {
    ssoEnabled: false,
    isFetched: false,
  },
  action
) => {
  if (action.type === FetchSSOEnabledActions.receive) {
    return Object.assign({}, state, {
      ssoEnabled: action.data.data.entity.ssoEnabled,
      isFetched: true,
    });
  } else {
    return state;
  }
};

export const maintenance = (
    state = {
      maintenance: false,
      norwegianText: undefined,
      englishText: undefined
    },
        action
) => {
  switch (action.type) {
    case Maintenance.setMaintenance:
      return Object.assign({}, state, {
        maintenance: action.data.maintenance,
        norwegianText: action.data.norwegianMaintenanceText,
        englishText: action.data.englishMaintenanceText
      });
    default:
      return state;
  }
}

const root = combineReducers({
  loginUser,
  categoryReducer,
  companyReducer,
  passwordChangeReducer,
  profileReducer,
  loginReducer,
  auditlogReducer,
  adminReducer,
  companyInformationReducer,
  messageReducer,
  permissionReducer,
  visibleModulesReducer,
  companyPermissionReducer,
  infoboardReducer,
  insiderReducer,
  financialReducer,
  insiderToolReducer,
  newUserReducer,
  notificationReducer,
  systemAdministratorReducer,
  config,
  ssoEnabled,
  form: form,
  emailDistributionReducer,
  insiderSyncDetailsReducer,
  issuanceReducer,
  maintenance,
});

export default root;
