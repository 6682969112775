import React from "react";

import { StyledNewsMessage } from "./StyledNewsMessage";
import { HeaderPanel } from "../components/HeaderPanel";
import { FlexContainer } from "../components/FlexContainer";
import Button from "../components/Button";
import styled from "styled-components";

const DeletePanel = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.spaceBetween ? "space-between;" : "flex-end;"};
  flex-direction: row;

  grid-row: 2/3;
  grid-column: 2/3;

  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;

  padding: 10px 0 0 0;
`;

const Icon = styled.img`
  cursor: pointer;
  padding: 0px 5px 0 5px;
  margin-right: 8px;
  box-sizing: border-box;
`;

const NavigationPanel = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  grid-row: 2/3;
  grid-column: 1/2;

  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;

  padding: 10px 0 0 0;
`;

const WarningText = styled.div`
  white-space: pre-wrap;
`;

/**
 * Displays details about a news message inline in the result table.
 * @param {*} props
 */
class NewsMessage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const props = this.props;
    const { t } = props;

    return (
      <FlexContainer>
        <HeaderPanel>{props.messageURL}</HeaderPanel>
        <StyledNewsMessage.ContentPanel>
          <StyledNewsMessage.AttributesPanel
            expandedGrid={props.hasMultipleLanguages || props.hasCorrections}
            attachmentboxIsVisible={props.attachmentList.length > 0}
          >
            <StyledNewsMessage.InfoBox>
              <StyledNewsMessage.Header>{t("status")}</StyledNewsMessage.Header>
              <StyledNewsMessage.Text>{props.status}</StyledNewsMessage.Text>
            </StyledNewsMessage.InfoBox>
            <StyledNewsMessage.TimeBox>
              <StyledNewsMessage.Header>{t("time")}</StyledNewsMessage.Header>
              <StyledNewsMessage.Text>
                {props.publishedTime}
              </StyledNewsMessage.Text>
            </StyledNewsMessage.TimeBox>

            <StyledNewsMessage.MessageIdBox>
              <StyledNewsMessage.Header>{t("issuer")}</StyledNewsMessage.Header>

              <StyledNewsMessage.Text>
                {props.companyName}
              </StyledNewsMessage.Text>
            </StyledNewsMessage.MessageIdBox>

            <StyledNewsMessage.IssuerBox role="contentinfo">
              <StyledNewsMessage.Header>
                {t("issuerId")}
              </StyledNewsMessage.Header>
              <StyledNewsMessage.Text>
                {props.companySymbol}
              </StyledNewsMessage.Text>
            </StyledNewsMessage.IssuerBox>

            {props.instrumentName ? (
              <StyledNewsMessage.IntrumentBox
                visible={props.instrumentName != null}
                role="contentinfo"
              >
                <StyledNewsMessage.Header>
                  {t("instrument")}
                </StyledNewsMessage.Header>
                <StyledNewsMessage.Text>
                  {props.instrumentName}
                </StyledNewsMessage.Text>
              </StyledNewsMessage.IntrumentBox>
            ) : undefined}

            <StyledNewsMessage.MarketBox role="contentinfo">
              <StyledNewsMessage.Header>{t("market")}</StyledNewsMessage.Header>
              <StyledNewsMessage.Text>{props.exchanges}</StyledNewsMessage.Text>
            </StyledNewsMessage.MarketBox>

            <StyledNewsMessage.CorrectedVersions
              visible={props.hasCorrections}
              role="contentinfo"
            >
              <StyledNewsMessage.Header>
                {t("corrected_versions")}
              </StyledNewsMessage.Header>
              <StyledNewsMessage.Text>
                {props.correctedVersionsLinks}
              </StyledNewsMessage.Text>
            </StyledNewsMessage.CorrectedVersions>

            <StyledNewsMessage.CategoryBox
              role="contentinfo"
              instrumentIsVisible={props.instrumentName != null}
              attachmentboxIsVisible={props.attachmentList.length > 0}
              expandedGrid={props.hasMultipleLanguages || props.hasCorrections}
            >
              <StyledNewsMessage.Header>
                {t("category")}
              </StyledNewsMessage.Header>
              <StyledNewsMessage.Text>{props.category}</StyledNewsMessage.Text>
              <StyledNewsMessage.List>
                {props.messageInfoList}
              </StyledNewsMessage.List>
            </StyledNewsMessage.CategoryBox>

            <StyledNewsMessage.AttatchmentBox
              expandedGrid={props.hasMultipleLanguages || props.hasCorrections}
            >
              {/*TODO: Refactor this out as a component. Its to complicated to be in this presentational component. */}
              {/* <StyledNewsMessage.HeaderWithIcon>
                {t("attach")}
                <FileButton
                  accept={this.props.fileType}
                  ref={ref => {
                    this.uploadInput = ref;

                    if (ref) {
                      this.uploadInput.onchange = e => {
                        const uploadedFiles = props.attachmentList.map(file => {
                          return {
                            fileName: file.attName,
                            type: FileUploadObjectType.LOCAL
                          };
                        });
                        const newFile = this.uploadInput.files[0];

                        if (newFile == null) return;

                        if (
                          fileValidator(
                            uploadedFiles,
                            newFile,
                            props.fileType,
                            props.maxFileSize
                          )
                        ) {
                          this.props.uploadFile({ file: newFile });
                          e.target.value = ""; // So that it is possible to select the same file twice if user deletes it first.
                        } else {
                          NotificationManager.warning(
                            <WarningText>
                              {this.props.t("rejected_attachments", {
                                fileSize: props.maxFileSize / 1000000,
                                numFiles: props.maxNumFiles
                              })}
                            </WarningText>,

                            "",
                            10000
                          );
                          NotificationManager.warning(
                            <WarningText>{newFile.name}</WarningText>,
                            "",
                            10000
                          );
                        }
                      };
                    }
                  }}
                />
              </StyledNewsMessage.HeaderWithIcon> */}
              <StyledNewsMessage.List>
                {(props.attachmentList || []).map((file, index) => (
                  <StyledNewsMessage.FileLi
                    key={index}
                    onClick={() => props.getAttachment(file.attId)}
                    onClickDelete={() => props.deleteAttachment(file)}
                    // showDeleteButton={props.showAttachmentButton}
                    onlyPDFIcon={this.props.onlyPDFIcon}
                  >
                    {file.attName}
                  </StyledNewsMessage.FileLi>
                ))}
              </StyledNewsMessage.List>
            </StyledNewsMessage.AttatchmentBox>
          </StyledNewsMessage.AttributesPanel>
          <StyledNewsMessage.Message>
            <StyledNewsMessage.MessageBodyHeadline>
              {props.title}
            </StyledNewsMessage.MessageBodyHeadline>
            <StyledNewsMessage.MessageBodyText
              textFormat={props.textFormat}
              role="document"
            >
              {props.body}
            </StyledNewsMessage.MessageBodyText>
          </StyledNewsMessage.Message>
          <NavigationPanel>
            <FlexContainer row vAlignCenter onClick={props.onCancel}>
              <Icon src="/icons/back-arrow-24-px.svg" />
              <Button.Text>{t("back")}</Button.Text>
            </FlexContainer>
          </NavigationPanel>
          <DeletePanel
            spaceBetween={
              this.props.showEditPublished &&
              this.props.messageStatus === "PUBLISHED" &&
              this.props.showDeleteButton
            }
          >
            {this.props.showEditPublished &&
            this.props.messageStatus === "PUBLISHED" ? (
              <Button.Standard
                onClick={() => {
                  this.props.onSuperEdit(this.props.id, this.props.cid);
                }}
              >
                {t("edit")}
              </Button.Standard>
            ) : (
              ""
            )}

            {this.props.showDeleteButton ? (
              <Button.Standard onClick={props.onDeleteMessage}>
                {t("delete_message")}
              </Button.Standard>
            ) : (
              ""
            )}
          </DeletePanel>
        </StyledNewsMessage.ContentPanel>
      </FlexContainer>
    );
  }
}

export default NewsMessage;
