import React from "react";
import PropTypes from "prop-types";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "../css/day-picker.css";
import styled from "styled-components";

import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import "moment/locale/nb";

import { withTranslation } from "react-i18next";
import moment from "moment";
import i18n from "../i18n";
import { NotificationManager } from "react-notifications";
import {
  browserIsIE,
  OBNTDateFormat,
  OBNTInsiderToolDateFormat
} from "../util/common";

const Container = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;

  width: 100%;
  height: 100%;
  height: 35px;

  ${props => (props.border ? "border: solid 1px #d0d0d0;" : "")}
  
  ${props => props.boxShadow ? "box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.3);" : ""}
    
    font-size: inherit;
  color: black;
`;
const Icon = styled.img`
  position: absolute;
  top: 7px;
  right: 8px;
  cursor: pointer;
`;

const dateParser = (date, format, locale) => {
  var check = null;
  if (date != null && moment(date, "DD.MM.YYYY", true).isValid()) {
    check = moment(date, "DD.MM.YYYY");

    const month = check.format("M");
    const day = check.format("D");
    const year = check.format("YYYY");

    return new Date(year, month - 1, day);
  } else if (date != null && moment(date, "DDMMYYYY", true).isValid()) {
    check = moment(date, "DDMMYYYY");

    const month = check.format("M");
    const day = check.format("D");
    const year = check.format("YYYY");

    return new Date(year, month - 1, day);
  } else if (date != null && moment(date, "DD/MM/YYYY", true).isValid()) {
    check = moment(date, "DD/MM/YYYY");

    const month = check.format("M");
    const day = check.format("D");
    const year = check.format("YYYY");

    return new Date(year, month - 1, day);
  } else if (date != null && moment(date, "DD-MM-YYYY", true).isValid()) {
    check = moment(date, "DD-MM-YYYY");

    const month = check.format("M");
    const day = check.format("D");
    const year = check.format("YYYY");

    return new Date(year, month - 1, day);
  } else if (date != null && moment(date, "YYYY-MM-DD", true).isValid()) {
    check = moment(date, "YYYY-MM-DD");

    const month = check.format("M");
    const day = check.format("D");
    const year = check.format("YYYY");

    return new Date(year, month - 1, day);
  } else if (date != null && moment(date, "YYYYMMDD", true).isValid()) {
    check = moment(date, "YYYYMMDD");

    const month = check.format("M");
    const day = check.format("D");
    const year = check.format("YYYY");

    return new Date(year, month - 1, day);
  }

  return null;
};

const Select = styled.select`
  font-size: 16px;
  font-family: inherit;
  text-transform: capitalize;
`;
class YearMonthForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;

    if (name === "month") this.props.onChange({ month: value });
    else this.props.onChange({ year: value });
  }

  render() {
    const locale = i18n.language === "nb" ? "nb" : "en";
    const months = this.props.localeUtils.getMonths(locale);
    const years = [];
    for (
      let i = this.props.fromMonth.getFullYear();
      i <= this.props.toMonth.getFullYear();
      i += 1
    ) {
      years.push(i);
    }

    return (
      <div className="DayPicker-Caption">
        <Select
          name="month"
          id="dpSelectMonth"
          onChange={this.handleChange}
          value={this.props.month}
        >
          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </Select>
        <Select
          id="dpSelectYear"
          name="year"
          onChange={this.handleChange}
          value={this.props.year}
        >
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </div>
    );
  }
}

class DayPicker extends React.Component {
  constructor(props) {
    super(props);
    let date = moment();

    // Need to figure out format of the input to not select the wrong month or year
    if (props.input && props.input.value) {
      date = this.getMomentFromDate(props.input.value);
    }

    this.state = {
      month: date ? date.month() : new Date().getMonth(),
      year: date ? date.year() : new Date().getFullYear()
    };
  }

  getMomentFromDate = dateString => {
    let date = moment();
    if (/([0-9]{4}-[0-9]{2}-[0-9]{2})/g.test(dateString)) {
      // Create moment object if input date is OBNTInsiderToolDateFormat - YYYY-MM-DD
      date = moment(dateString, OBNTInsiderToolDateFormat);
    } else if (/([0-9]{2}-[0-9]{2}-[0-9]{4})/g.test(dateString)) {
      // Create moment object if input date is OBNTDateFormat - DD-MM-YYYY
      date = moment(dateString, OBNTDateFormat);
    }
    return date;
  };

  render() {
    const {
      keepValueOnBlurOrEnter,
      disableDaysBefore,
      disableDaysAfter
    } = this.props;

    const { name, onChange, value } = this.props.input;
    const placeholder = this.props.placeholder !== undefined ? this.props.placeholder : "";

    var disabledDays = {};
    if (this.props.disabledDays)
      disabledDays.modifiers = {
        disabled: this.props.disabledDays
      };

    if (disableDaysAfter || disableDaysBefore)
      disabledDays.disabledDays = {
        before: disableDaysBefore,
        after: disableDaysAfter
      };

    return (
      <Container
        boxShadow={this.state.isOpen}
        border={this.props.border}
        onKeyDown={e => {
          const regex = new RegExp("^[0-9-./]+$");

          if (
            !regex.test(e.key) &&
            e.keyCode !== 8 &&
            e.keyCode !== 13 &&
            e.keyCode !== 9 &&
            e.keyCode !== 46 &&
            e.keyCode !== 35 &&
            e.keyCode !== 36 &&
            (e.keyCode < 37 || e.keyCode > 40)
          ) {
            e.preventDefault();
            if (e.keyCode !== 16 && e.keyCode !== 17 && e.keyCode !== 20) {
              NotificationManager.error(
                this.props.t("invalid_character", { character: e.key }),
                "",
                2000
              );
            }
          } else {
            switch (e.keyCode) {
              case 13: //Enter
                e.preventDefault();
                if (
                  dateParser(this.dayPicker.getInput().value) === null &&
                  !keepValueOnBlurOrEnter
                ) {
                  onChange("");
                  this.dayPicker.setState({ value: "" });
                } else {
                  onChange(this.dayPicker.getInput().value);
                }
                this.dayPicker.getInput().blur();
                this.setState({ isOpen: false });

              default:
            }
          }
        }}
      >
        <DayPickerInput
          inputProps={{
            name: name,
            onFocus: () =>
              (this.dayPicker.getInput().placeholder = this.props.dateFormat),
            onBlur: () => {
              this.dayPicker.getInput().placeholder = this.props.placeholder;
              if (
                dateParser(this.dayPicker.getInput().value) === null &&
                !keepValueOnBlurOrEnter
              ) {
                onChange("");
              } else if (keepValueOnBlurOrEnter) {
                onChange(this.dayPicker.getInput().value);
              }
            }
          }}
          formatDate={formatDate}
          parseDate={dateParser}
          onDayChange={day => {
            if (day != null) {
              const formatedDay = formatDate(day, this.props.dateFormat, "nb");
              const momentFormattedDate = this.getMomentFromDate(formatedDay);
              this.setState({
                year: momentFormattedDate.year()
              });
              onChange(formatedDay);
            }
          }}
          keepFocus={browserIsIE() ? false : true}
          format={this.props.dateFormat}
          value={value || ""}
          placeholder={placeholder}
          dayPickerProps={{
            containerProps: {
              onFocus: e => {
                if (
                  !(
                    e.target.id === "dpSelectMonth" ||
                    e.target.id === "dpSelectYear"
                  )
                )
                  this.dayPicker.getInput().focus();
              },
              onBlur: e => {
                if (
                  e.target.id === "dpSelectMonth" ||
                  e.target.id === "dpSelectYear"
                )
                  this.dayPicker.getInput().focus();
              }
            },
            captionElement: ({ _, localeUtils }) => {
              const currentYear = new Date().getFullYear();

              return (
                <YearMonthForm
                  fromMonth={
                    new Date(currentYear - this.props.numPreviousYears, 0)
                  }
                  toMonth={
                    new Date(currentYear + this.props.numFutureYears, 11)
                  }
                  month={this.state.month}
                  year={this.state.year}
                  localeUtils={localeUtils}
                  onChange={date => {
                    this.setState(date);

                    if (browserIsIE()) this.dayPicker.getInput().focus();
                  }}
                />
              );
            },
            onMonthChange: date => {
              this.setState({
                month: date.getMonth(),
                year: date.getFullYear()
              });
              if (browserIsIE()) this.dayPicker.getInput().focus();
            },
            month: new Date(this.state.year, this.state.month),

            locale: i18n.language === "nb" ? "nb" : "en",
            localeUtils: MomentLocaleUtils,
            ...disabledDays
          }}
          ref={dayPicker => {
            if (this.dayPicker) return;
            this.dayPicker = dayPicker;
          }}
        />
        <Icon
          alt={this.props.placeholder}
          onClick={() => {
            this.dayPicker.showDayPicker();
            this.dayPicker.getInput().placeholder = this.props.dateFormat;
            this.dayPicker.getInput().focus();
          }}
          src="/icons/ic-date-range-black-24-px.svg"
        />
      </Container>
    );
  }
}

DayPicker.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  t: PropTypes.func.isRequired
};

DayPicker.defaultProps = {
  placeholder: "",
  numFutureYears: 0,
  numPreviousYears: 10,
  dateFormat: "DD.MM.YYYY"
};
export default withTranslation("translations")(DayPicker);
