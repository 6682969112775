import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { FlexContainer } from "../components/FlexContainer";
import Table, {TD, TR} from "../components/Table";
import {ConfirmModal} from "../components/ConfirmModal";
import Button from "../components/Button";
import {getLanguage, getOBNTDateTime, hasValue, isSuperUser} from "../util/common";
import Loader from "../components/Loader";
import moment from "moment/moment";
import {deleteIssuance, fetchIssuanceList} from "./ShareIssuanceActions";
import {NotificationManager} from "react-notifications";
import SearchableSelectNew from "../components/SearchableSelectNew";
import Label from "../components/Label";
import DayPicker from "../components/DayPicker";
import CheckBox from "../components/CheckBox";
import {Text} from "./ChatBox";
import styled from "styled-components";
import {getCorrectDateFormat, mapStatus} from "./ShareIssuanceContainer";
import Link from "../components/Link";

const Clickable = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(51, 102, 153);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const StatusInfoHeader = styled.h4`
  font-weight: 600;
  font-size: 16px;
  margin: 0 0 5px 10px;
`;
const StatusInfoContent = styled.div`
  white-space: pre-wrap;
  font-size: 14px;
  margin: 0 0 2px 10px;
`;

const BoldStatusText = styled.span`
    font-weight: 600;
`;

class ShareIssuanceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDeleteModal: false,
            entityToDelete: undefined,
            selectedIssuer: undefined,
            status: undefined,
            fromDate: undefined,
            toDate: undefined,
            showStatusInfo: false
        };
    }

    showDeleteModal(entity) {
        this.setState({ showDeleteModal: true, entityToDelete: entity});
    }

    deleteForm() {
        this.props.dispatch(deleteIssuance(this.state.entityToDelete))
            .then(() => {
            this.setState({
                showDeleteModal: false,
                entityToDelete: undefined
            })
        }).then(() => {
            NotificationManager.success(this.props.t("share_delete_success"))
        }).then(() => {
            this.props.dispatch(fetchIssuanceList())
        })
    }

    render() {
        const { t, base, parameters } = this.props;

        const tableLabels = [t("created"), t("issuer"), t("list_created_by"),t("updated_by"),t("updated"), t("status"), t("delete")]
        const headerProps = [undefined, undefined, undefined,undefined,undefined, undefined, { center: true }];
        const tableLabelsSuper = [t("created"), t("issuer"), t("list_created_by"), t("updated_by"),t("updated"), t("status"), t("share_closed"), t("delete")]
        const headerPropsSuper = [undefined, undefined, undefined, undefined, undefined, undefined, { center: true }, { center: true }];

        const showDelete = (status, id) => {
            if (this.props.isSuperUser || status===0) {
                return (
                    <TD pointer center >
                        <img
                            alt={"Delete symbol"}
                            src="/icons/red-cross-24.svg"
                            onClick={() => this.showDeleteModal(id)}
                        />
                    </TD>
                )
            } else {
                return <TD/>
            }
        }

        const sortedList = this.props.data.sort((a, b) => {
            const aPub = moment(a.createdTime);
            const bPub = moment(b.createdTime);

            if (aPub.isAfter(bPub)) return -1;
            else if (aPub.isBefore(bPub)) return 1;
            return 0;
        })

        const tableRows = this.props.data != null ? sortedList.map((entity, index) => (
            <TR key={index}>
                <TD pointer onClick={() => this.props.onListEntityClick(entity.id)}><Clickable>{getOBNTDateTime(entity.createdTime)}</Clickable></TD>
                <TD>{entity.issuerSign}</TD>
                <TD>{entity.createdBy}</TD>
                <TD>{entity.updatedBy}</TD>
                <TD>{getOBNTDateTime(entity.updatedTime)}</TD>
                <TD>{mapStatus(parameters?.statusTypes, entity.status, t)}</TD>
                {this.props.isSuperUser && (<TD>
                    <CheckBox center checked={entity.closed} disabled/></TD>)}
                {showDelete(entity.status, entity.id)}
            </TR>
            )) : [];

        const doSearch = () => {
            let params = {};
            if (this.state.toDate) {
                params.toDate = getCorrectDateFormat(this.state.toDate);
            }
            if (this.state.fromDate) {
                params.fromDate = getCorrectDateFormat(this.state.fromDate);
            }
            if (this.state.status) {
                params.status = this.state.status;
            }
            if (this.state.selectedIssuer) {
                params.issuer = this.state.selectedIssuer;
            }
            this.props.dispatch(fetchIssuanceList(params));
        };

        if (this.props.loading) {
            return <Loader />;
        }

        return (
            <FlexContainer>
                <FlexContainer row>
                    <Button.Standard margin={[0,0,10,0]}
                                 onClick={() => this.props.history.push(`${base}/form`)}
                        >{t("share_new_form")}</Button.Standard>
                </FlexContainer>
                {tableRows?.length > 0 &&
                    <FlexContainer
                        greyBackground
                        padding={[8, 8, 8, 8]}
                        margin={[0, 0, 20, 0]}
                    >
                        <StatusInfoHeader>{t("share_status_information")}</StatusInfoHeader>
                        <StatusInfoContent>
                            {this.state.showStatusInfo ?
                                <FlexContainer>
                                    <FlexContainer row><BoldStatusText>{t("share_status_created")}</BoldStatusText>{t("share_status_information_created")}</FlexContainer>
                                    <FlexContainer row><BoldStatusText>{t("share_status_processing")}</BoldStatusText>{t("share_status_information_processing")}</FlexContainer>
                                    <FlexContainer row><BoldStatusText>{t("share_status_follow_up")}</BoldStatusText>{t("share_status_information_follow_up")}</FlexContainer>
                                    <FlexContainer row><BoldStatusText>{t("share_status_updated_by_issuer")}</BoldStatusText>{t("share_status_information_updated_by_issuer")}</FlexContainer>
                                    <FlexContainer row><BoldStatusText>{t("share_status_processed")}</BoldStatusText>{t("share_status_information_processed")}</FlexContainer>
                                    <Link
                                        margin={[5, 0, 0, 0]}
                                        onClick={() => {
                                            this.setState({ showStatusInfo: false });
                                        }}
                                    >
                                        {t("show_less")}
                                    </Link>
                                </FlexContainer>
                                :
                                <Link
                                    margin={[5, 0, 0, 0]}
                                    onClick={() => {
                                        this.setState({ showStatusInfo: true });
                                    }}
                                >
                                    {t("show_more")}
                                </Link>
                            }
                        </StatusInfoContent>
                    </FlexContainer>
                }
                {
                    this.props.isSuperUser &&
                    <FlexContainer row margin={[10, 0]}>
                        <FlexContainer marginRight="10">
                            <Label bold margin={[0,0,2,0]}>{t("issuer")}</Label>
                            <SearchableSelectNew t={t} options={this.props.parameters?.issuers?.map((opt) => {
                                                            return {label: opt["label" + getLanguage().toUpperCase()], value: opt.value}
                                                        }) || []}
                                                     onSelect={(selected) => {
                                                         this.setState({selectedIssuer: selected?.value})
                                                     }}
                                                    value={this.state.selectedIssuer}
                                                 />
                        </FlexContainer>
                        <FlexContainer marginRight="10">
                            <Label bold margin={[0,0,2,0]}>{t("status")}</Label>
                            <SearchableSelectNew t={t} options={this.props.parameters?.statusTypes?.map((opt) => {
                                                    return {label: opt["label" + getLanguage().toUpperCase()], value: opt.value}
                                                }) || []}
                                                 onSelect={(selected) => {
                                                     this.setState({status: selected?.value + "" || undefined})
                                                 }}
                                                 value={this.state.status}
                            />
                        </FlexContainer>
                        <FlexContainer marginRight="10">
                            <Label bold margin={[0,0,2,0]}>{t("fromDate")}</Label>
                            <DayPicker
                                border
                                numFutureYears={0}
                                numPreviousYears={2}
                                disableDaysAfter={new Date()}
                                input={{
                                    name:"fromDate",
                                    onChange: (value) => this.setState({fromDate: value})
                                }}
                            />
                        </FlexContainer>
                        <FlexContainer>
                            <Label bold margin={[0,0,2,0]}>{t("toDate")}</Label>
                            <DayPicker
                                border
                                numFutureYears={0}
                                numPreviousYears={2}
                                disableDaysAfter={new Date()}
                                input={{
                                    name:"toDate",
                                    onChange: (value) => this.setState({toDate: value})
                                }}
                            />
                        </FlexContainer>
                        <FlexContainer flexEnd vAlignEnd>
                            <Button.Standard margin={[20, 10, 0, 0]}
                                             onClick={doSearch}>
                                {t("search")}
                            </Button.Standard>
                        </FlexContainer>
                    </FlexContainer>
                }
                {
                    tableRows.length > 0 ?
                        <Table
                            tableRows={tableRows}
                            tableHeaderLabels={this.props.isSuperUser ? tableLabelsSuper : tableLabels}
                            tableHeaderProps={this.props.isSuperUser ? headerPropsSuper : headerProps}
                            t={t}
                        />
                        :
                        <Text>{t("share_no_entries")} </Text>
                }
                <ConfirmModal
                    isOpen={this.state.showDeleteModal}
                       onClose={() =>
                           this.setState({
                               showDeleteModal: false,
                               entityToDelete: undefined
                           })
                       }
                       width={"40%"}
                       header={t("share_delete_headline")}
                      onConfirm={() => this.deleteForm()}
                      cancelText={t("cancel")}
                      confirmText={t("okay")}
                >
                    <FlexContainer center vAlignCenter>{t("share_delete_text")}</FlexContainer>
                </ConfirmModal>

            </FlexContainer>
        );
    }
}

ShareIssuanceList.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default connect()(withTranslation("translations")(withRouter(ShareIssuanceList)));
