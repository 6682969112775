import {doDeleteRequest, doGetRequest, doPostRequest, doPutRequest,} from "../noObsvcClient";
import {TWO_FACTOR_PROP_KEY} from "./CommonActions";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const SSO_ENABLED_PROP_KEY = "int.system.global.ssoEnabled";
export const MAINTENANCE_ENABLED_PROP_KEY = "int.system.global.maintenanceEnabled";
export const MAINTENANCE_NORWEGIAN_TEXT_PROP_KEY = "int.system.global.maintenanceNorwegianText";
export const MAINTENANCE_ENGLISH_TEXT_PROP_KEY = "int.system.global.maintenanceEnglishText";

export const fetchData = (actions, params, path, showServerError, timeoutInSeconds) => {
  const request = () => {
    return { type: actions.request };
  };
  const receive = (data) => {
    return { type: actions.receive, data };
  };
  const receiveFailed = (error) => {
    return { type: actions.receiveFail, error };
  };

  const executer = async (dispatch, getState) => {
    while (getState().loginReducer.login.tokenRenewalInProgress) {
      await sleep(200);
    }
    dispatch(request());

    return doGetRequest(path, params, showServerError, true, timeoutInSeconds).then(
      (data) => {
        dispatch(receive(data));
        return data;
      },
      (error) => {
        dispatch(receiveFailed(error));
        throw error;
      }
    );
  };

  return executer;
};

export const postData = (
  actions,
  params,
  body,
  path,
  convertToJSON = true,
  onSuccess = () => undefined,
  onFailure = () => undefined,
  showServerError
) => {
  if (showServerError === undefined) {
    showServerError = true
  }
  const request = () => {
    return { type: actions.request | actions.post };
  };
  const postSuccess = (data) => {
    return { type: actions.postSuccess, data };
  };
  const postFailed = (error) => {
    return { type: actions.postFail, error };
  };

  return async (dispatch, getState) => {
    while (getState().loginReducer.login.tokenRenewalInProgress) {
      await sleep(200);
    }

    dispatch(request());

    return doPostRequest(path, params, body, showServerError, true, convertToJSON).then(
      (data) => {
        dispatch(postSuccess(data));
        onSuccess(data);
        return data;
      },
      (error) => {
        dispatch(postFailed(error));
        onFailure(error);
        throw error;
      }
    );
  };
};

export const deleteData = (actions, path, params) => {
  const request = () => {
    return { type: actions.request };
  };
  const deleteSuccess = (data) => {
    return { type: actions.deleteSuccess, data };
  };
  const deleteFailed = (error) => {
    return { type: actions.deleteFail, error };
  };

  const executer = async (dispatch, getState) => {
    while (getState().loginReducer.login.tokenRenewalInProgress) {
      await sleep(200);
    }

    dispatch(request());

    return doDeleteRequest(path, params).then(
      (data) => {
        dispatch(deleteSuccess(data));
        return data;
      },
      (error) => {
        dispatch(deleteFailed(error));
        throw error;
      }
    );
  };

  return executer;
};

export const putData = (actions, params, body, path) => {
  const request = () => {
    return { type: actions.request };
  };
  const putSuccess = (data) => {
    return { type: actions.putSuccess, data };
  };
  const putFailed = (error) => {
    return { type: actions.putFail, error };
  };

  const executer = async (dispatch, getState) => {
    while (getState().loginReducer.login.tokenRenewalInProgress) {
      await sleep(200);
    }

    dispatch(request());

    return doPutRequest(path, params, body).then(
      (data) => {
        dispatch(putSuccess(data));
        return data;
      },
      (error) => {
        dispatch(putFailed(error));
        throw error;
      }
    );
  };

  return executer;
};
export const FetchSSOEnabledActions = {
  request: "REQUEST_SSO_ENABLED",
  receive: "RECEIVE_SSO_ENABLED",
  receiveFail: "RECEIVE_SSO_ENABLED_FAILED",
};
export const fetchSSOEnabled = () =>
  fetchData(
    FetchSSOEnabledActions,
    undefined,
    "v1/sysadmin/isSSOEnabled",
    false
  );

export const FetchEnv = {
  request: "REQUEST_ENV",
  receive: "RECEIVE_ENV",
  receiveFail: "RECEIVE_ENV_FAILED",
};
export const fetchEnv = () =>
    fetchData(
        FetchEnv,
        undefined,
        "v1/sysadmin/env",
        false,
        8
    );

export const SSOStatusActions = {
  request: "UPDATE_SSO_STATUS_REQUEST",
  postSuccess: "UPDATE_SSO_STATUS_SUCCESS",
  postFail: "UPDATE_SSO_STATUS_FAILED",
};

export const updateSSOStatus = (status) =>
{
  return postData(
      SSOStatusActions,
      undefined,
      {
        sysProps: {
          [SSO_ENABLED_PROP_KEY]: status,
        },
      },
      "v1/sysadmin/updateSysProps"
  );
}

export const MaintenanceStatusActions = {
  request: "UPDATE_MAINTENANCE_STATUS_REQUEST",
  postSuccess: "UPDATE_MAINTENANCE_STATUS_SUCCESS",
  postFail: "UPDATE_MAINTENANCE_STATUS_FAILED",
};

export const updateMaintenanceStatus = (values) =>
  postData(
      MaintenanceStatusActions,
    undefined,
    {
      sysProps: {
        [MAINTENANCE_ENABLED_PROP_KEY]: values.status,
        [MAINTENANCE_NORWEGIAN_TEXT_PROP_KEY]: values.norwegianText,
        [MAINTENANCE_ENGLISH_TEXT_PROP_KEY]: values.englishText,
      },
    },
    "v1/sysadmin/updateSysProps"
  );

export const TwoFactorStatusActions = {
  request: "UPDATE_TWO_FACTOR_STATUS_REQUEST",
  postSuccess: "UPDATE_TWO_FACTOR_STATUS_SUCCESS",
  postFail: "UPDATE_TWO_FACTOR_STATUS_FAILED",
};

export const updateTwoFactorStatus = (status) =>
  postData(
    TwoFactorStatusActions,
    undefined,
    {
      sysProps: {
        [TWO_FACTOR_PROP_KEY]: status,
      },
    },
    "v1/sysadmin/updateSysProps"
  );

export const Maintenance = {
  setMaintenance: "SET_MAINTENANCE",
}

export const setMaintenance = (data) => {
  return { type: Maintenance.setMaintenance, data };
}
